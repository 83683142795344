import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { Story } from '../../../types.ts/story';
import { videoCreator } from '../../../stores/VideoCreatorStore';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import {
  downloadFileFromS3,
  ProductionResult,
  startProductionToRemoveMusicFromVideo,
} from '../../../utility/auphonic';

export enum Step {
  one = 1,
  two,
  three,
  four,
  five,
}

export type ArtifactUpload = SimpleSchemaTypes.Upload & { _internalId: string };
export type ArtifactFile = File & { _internalId: string };

type ReturnType = {
  step: Step;
  goToNextStep: () => void;
  canGoToStep: (s: number) => boolean;
  handleStoryVideoUpload: (f: File[]) => void;
  handleStoryBRollUpload: (f: File[]) => void;
  storyName: string;
  setStoryName: (s: string) => void;
  storyType: 'raw' | 'produced';
  setStoryType: (t: 'raw' | 'produced') => void;
  videoMusicStrategy: 'keep' | 'remove';
  setVideoMusicStrategy: (s: 'keep' | 'remove') => void;
  storyArtifactsFiles: ArtifactFile[];
  storyArtifactsVideoFiles: ArtifactFile[];
  storyArtifacts: ArtifactUpload[];
  storyArtifactsVideo: ArtifactUpload[];
  saveArtifactNotes: (internalId: string) => void;
  setArtifactNotes: (internalId: string, notes: string) => void;
  deleteArtifact: (internalId: string) => void;
  saveArtifactVideoNotes: (internalId: string) => void;
  setArtifactVideoNotes: (internalId: string, notes: string) => void;
  deleteArtifactVideo: (internalId: string) => void;
};

type Params = {
  onStoryInitialized: (s: Story) => void;
  onStoryCreated: (s: Story) => void;
  onStoryProgress: (percent: number) => void;
};

export function useStoryCreator(params: Params): ReturnType {
  const [step, setStep] = useState<Step>(1);
  const [story, setStory] = useState<Story>();

  // step 1 data
  const [storyVideoFile, setStoryVideoFile] = useState<File | null>(null);
  const [storyVideoUpload, setStoryVideoUpload] =
    useState<SimpleSchemaTypes.Upload | null>(null);

  // step 2 data
  const [storyType, setStoryType] = useState<'raw' | 'produced'>('raw');
  const isRemovingMusicRef = useRef<boolean>(false);
  const [videoMusicStrategy, setVideoMusicStrategy] = useState<
    'keep' | 'remove'
  >('keep');
  const [storyVideoUploadWithoutMusic, setStoryVideoUploadWithoutMusic] =
    useState<SimpleSchemaTypes.Upload | null>(null);

  // step 3 data
  const [storyName, setStoryName] = useState<string>('');
  const [storyTellerName, _setStoryTellerName] = useState<string>(
    videoCreator?.organization?.title || 'no_org',
  );
  const [storyTellerTitle, _setStoryTellerTitle] = useState<string>(
    'Organization storyteller',
  );

  // step 4 data
  const [storyArtifactsFiles, setStoryArtifactsFiles] = useState<
    ArtifactFile[]
  >([]);
  const [storyArtifacts, setStoryArtifacts] = useState<ArtifactUpload[]>([]);
  const [storyArtifactsVideoFiles, setStoryArtifactsVideoFiles] = useState<
    ArtifactFile[]
  >([]);
  const [storyArtifactsVideo, setStoryArtifactsVideo] = useState<
    ArtifactUpload[]
  >([]);
  const [bRollReady, setBRollReady] = useState<boolean>(false);
  const storyArtifactsRef = useRef<ArtifactUpload[]>([]);
  const storyArtifactsFilesRef = useRef<ArtifactFile[]>([]);
  const storyArtifactsVideoRef = useRef<ArtifactUpload[]>([]);
  const storyArtifactsVideoFilesRef = useRef<ArtifactFile[]>([]);
  const lastProgressPercentRef = useRef<number>(0);

  // regular upload for story's original video is done - time to attach it to story if it's not already attached
  useEffect(() => {
    if (
      story &&
      !story?.originalVideo &&
      storyVideoUpload &&
      !(storyType === 'produced' && videoMusicStrategy === 'remove')
    ) {
      (async () => {
        await videoCreator.updateStory({ id: story.id }, storyVideoUpload.id);
      })();
    }
  }, [storyVideoUpload, story, storyType, videoMusicStrategy]);

  // option to remove music from video is selected - start production to remove music
  useEffect(() => {
    if (
      storyVideoFile &&
      storyType === 'produced' &&
      videoMusicStrategy === 'remove' &&
      !isRemovingMusicRef.current
    ) {
      isRemovingMusicRef.current = true;
      startProductionToRemoveMusicFromVideo({
        inputFile: storyVideoFile,
        onProductionDone: handleProductionDone,
        onError: handleProductionError,
      });
    }
  }, [storyVideoFile, storyType, videoMusicStrategy]);

  // upload of video with removed music is done - time to attach it to story if it's not already attached
  useEffect(() => {
    if (story && !story?.originalVideo && storyVideoUploadWithoutMusic) {
      (async () => {
        await videoCreator.updateStory(
          { id: story.id },
          storyVideoUploadWithoutMusic.id,
        );
      })();
    }
  }, [storyVideoUploadWithoutMusic, story]);

  useEffect(() => {
    if (step === Step.four && !story) {
      handleStoryCreate();
    }
    if (step === Step.five && story?.id) {
      lastProgressPercentRef.current = Infinity; // stop progress updates after story is initialized
      params.onStoryInitialized(story);
    }
  }, [step, story?.id]);

  const storyVideoUploadReady =
    storyType === 'produced' && videoMusicStrategy === 'remove'
      ? !!storyVideoUploadWithoutMusic
      : !!storyVideoUpload;
  useEffect(() => {
    if (step === Step.five && story && storyVideoUploadReady && bRollReady) {
      params.onStoryCreated(story);
    }
  }, [step, story, storyVideoUploadReady, bRollReady]);

  useEffect(() => {
    // to prevent methods' closure from capturing stale state
    storyArtifactsRef.current = storyArtifacts;
    storyArtifactsFilesRef.current = storyArtifactsFiles;
    storyArtifactsVideoRef.current = storyArtifactsVideo;
    storyArtifactsVideoFilesRef.current = storyArtifactsVideoFiles;

    // b-roll is uploaded when story artifacts' length matches files' length
    if (
      storyArtifacts.length === storyArtifactsFiles.length &&
      storyArtifactsVideo.length === storyArtifactsVideoFiles.length
    ) {
      // if we are at the last step, time to update story with uploaded b-roll and mark b-roll as ready
      // doing it at the last step makes it less error prone, because b-roll can change multiple times at step 4
      if (story && step === Step.five) {
        (async () => {
          if (
            storyArtifactsFiles.length > 0 ||
            storyArtifactsVideoFiles.length > 0
          ) {
            const storyUpdate = await videoCreator.updateStory({
              id: story.id,
              storyArtifacts: storyArtifacts.map((a) => ({
                id: a.id,
              })),
              storyArtifactsVideo: storyArtifactsVideo.map((a) => ({
                id: a.id,
              })),
            });
            setStory({
              ...story,
              ...storyUpdate,
            });
          }
          setBRollReady(true);
        })();
      }
      // initial b-roll upload is done - time to update progress, prematurely
      if (
        storyArtifactsFiles.length > 0 ||
        storyArtifactsVideoFiles.length > 0 ||
        step === Step.five
      ) {
        callStoryProgressCbOnceFor(50);
      }
    }
  }, [
    storyArtifacts,
    storyArtifactsFiles,
    storyArtifactsVideo,
    storyArtifactsVideoFiles,
    step,
    story?.id,
  ]);

  const callStoryProgressCbOnceFor = (percent: number) => {
    if (percent > lastProgressPercentRef.current) {
      lastProgressPercentRef.current = percent;
      params.onStoryProgress(percent);
    }
  };

  const handleProductionError = (error: any) => {
    console.error('Removing music production error', error);
    isRemovingMusicRef.current = false;
    setVideoMusicStrategy('keep'); // to proceed with regular upload
  };

  const handleProductionDone = async (result: ProductionResult) => {
    try {
      const video = result.output_files.find((f) => f.format === 'video');
      if (!video) {
        throw new Error('No video file in production result');
      }
      const videoFilename = `nomusic-${storyVideoFile?.name || video.filename}`;
      const blob = await downloadFileFromS3(video.filename);
      (blob as any).name = videoFilename;
      const videoUploadWithoutMusic =
        await videoCreator.datoClient?.uploads.createFromFileOrBlob({
          fileOrBlob: blob,
          filename: videoFilename,
        });
      if (videoUploadWithoutMusic) {
        setStoryVideoUploadWithoutMusic(videoUploadWithoutMusic);
      }
    } catch (err) {
      console.error('onProductionDone error', err);
      setVideoMusicStrategy('keep'); // to proceed with regular upload
    }
    isRemovingMusicRef.current = false;
  };

  const handleStoryCreate = async () => {
    const uploadId =
      storyType === 'produced' && videoMusicStrategy === 'remove'
        ? undefined
        : storyVideoUpload?.id;

    setStory(
      await videoCreator.createStory(
        {
          title: storyName,
          storyType: storyType,
          storyTeller: {
            name: storyTellerName,
            id: (
              await videoCreator.upsertStoryTeller({
                name: storyTellerName,
                title: storyTellerTitle,
              })
            ).id,
          },
          primaryShowcase: videoCreator.organization,
          byExternalUser: videoCreator.currentUserType === 'external',
        },
        uploadId,
      ),
    );
  };

  const handleStoryVideoUpload = async (files: File[]) => {
    if (!files.length) {
      console.error('No files at AddStoriesModal handleStoryVideoUpload');
      return;
    }
    setStoryVideoFile(files[0]);
    callStoryProgressCbOnceFor(25);
    goToNextStep();

    try {
      const videoUpload =
        await videoCreator.datoClient?.uploads.createFromFileOrBlob({
          fileOrBlob: files[0],
          filename: files[0].name,
        });
      setStoryVideoUpload(videoUpload || null);
    } catch (err) {
      setStoryVideoUpload(null);
      console.error('Story video upload failed', err);
    }
  };

  const handleStoryBRollUpload = async (files: File[]) => {
    const imageFiles = files.filter((f) =>
      f.type.includes('image'),
    ) as ArtifactFile[];
    imageFiles.forEach((f) => {
      f._internalId = uuid();
    });

    const videoFiles = files.filter((f) =>
      f.type.includes('video'),
    ) as ArtifactFile[];
    videoFiles.forEach((f) => {
      f._internalId = uuid();
    });

    setStoryArtifactsFiles(storyArtifactsFilesRef.current.concat(imageFiles));
    setStoryArtifactsVideoFiles(
      storyArtifactsVideoFilesRef.current.concat(videoFiles),
    );

    let newStoryArtifacts = [...storyArtifactsRef.current];
    imageFiles.forEach((file) => {
      videoCreator.datoClient?.uploads
        .createFromFileOrBlob({
          fileOrBlob: file,
          filename: file.name,
        })
        .then((u) => {
          newStoryArtifacts = newStoryArtifacts.concat({
            ...u,
            _internalId: file._internalId,
          });
          setStoryArtifacts(newStoryArtifacts);
        });
    });

    let newStoryArtifactsVideo = [...storyArtifactsVideoRef.current];
    videoFiles.forEach((file) => {
      videoCreator.datoClient?.uploads
        .createFromFileOrBlob({
          fileOrBlob: file,
          filename: file.name,
        })
        .then((u) => {
          newStoryArtifactsVideo = newStoryArtifactsVideo.concat({
            ...u,
            _internalId: file._internalId,
          });
          setStoryArtifactsVideo(newStoryArtifactsVideo);
        });
    });
  };

  const goToNextStep = () => {
    setStep(step + 1);
  };

  const canGoToStep = (nextStep: number): boolean => {
    if (nextStep === Step.two) {
      return !!storyVideoFile;
    }
    if (nextStep === Step.four) {
      return !!storyName && !!(storyTellerName || '').trim();
    }
    if (nextStep === Step.five) {
      return !!story;
    }
    return true;
  };

  const saveArtifactNotes = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsRef.current.find(
      (a) => a._internalId === internalId,
    );
    if (!artifact) {
      return;
    }
    await videoCreator.assetRepository?.update(artifact.id, {
      title: artifact.notes || '',
      alt: artifact.notes || '',
    });
  };

  const setArtifactNotes = (internalId: string, notes: string): void => {
    setStoryArtifacts(
      storyArtifactsRef.current.map((a) =>
        a._internalId === internalId ? { ...a, notes } : a,
      ),
    );
  };

  const deleteArtifact = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsRef.current.find(
      (a) => a._internalId === internalId,
    );
    if (!artifact) {
      return;
    }

    setStoryArtifacts(
      storyArtifactsRef.current.filter((a) => a._internalId !== internalId),
    );
    setStoryArtifactsFiles(
      storyArtifactsFilesRef.current.filter(
        (f) => f._internalId !== internalId,
      ),
    );

    try {
      await videoCreator.assetRepository?.delete(artifact.id);
    } catch (err) {
      console.error('Error deleting b-roll', err);
    }
  };

  const saveArtifactVideoNotes = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsVideoRef.current.find(
      (a) => a._internalId === internalId,
    );
    if (!artifact) {
      return;
    }
    await videoCreator.assetRepository?.update(artifact.id, {
      title: artifact.notes || '',
      alt: artifact.notes || '',
    });
  };

  const setArtifactVideoNotes = (internalId: string, notes: string): void => {
    setStoryArtifactsVideo(
      storyArtifactsVideoRef.current.map((a) =>
        a._internalId === internalId ? { ...a, notes } : a,
      ),
    );
  };

  const deleteArtifactVideo = async (internalId: string): Promise<void> => {
    const artifact = storyArtifactsVideoRef.current.find(
      (a) => a._internalId === internalId,
    );
    if (!artifact) {
      return;
    }

    setStoryArtifactsVideo(
      storyArtifactsVideoRef.current.filter(
        (a) => a._internalId !== internalId,
      ),
    );
    setStoryArtifactsVideoFiles(
      storyArtifactsVideoFilesRef.current.filter(
        (f) => f._internalId !== internalId,
      ),
    );

    try {
      await videoCreator.assetRepository?.delete(artifact.id);
    } catch (err) {
      console.error('Error deleting b-roll', err);
    }
  };

  return {
    step,
    goToNextStep,
    canGoToStep,
    handleStoryVideoUpload,
    handleStoryBRollUpload,
    storyName,
    setStoryName,
    storyType,
    setStoryType,
    videoMusicStrategy,
    setVideoMusicStrategy,
    storyArtifactsFiles,
    storyArtifactsVideoFiles,
    storyArtifacts,
    storyArtifactsVideo,
    saveArtifactNotes,
    setArtifactNotes,
    deleteArtifact,
    saveArtifactVideoNotes,
    setArtifactVideoNotes,
    deleteArtifactVideo,
  };
}
