import { computed, makeAutoObservable, runInAction, toJS } from 'mobx';
import { v4 as uuid } from 'uuid';
import { RenderModalCtx } from 'datocms-plugin-sdk';
import { Renderer } from '../renderer/Renderer';
import { RendererState } from '../renderer/RendererState';
import { ElementState } from '../renderer/ElementState';
import { groupBy } from '../utility/groupBy';
import { deepClone } from '../utility/deepClone';
import { createGQLClient } from '../utility/dato';
import {
  ORIGINAL_VIDEO_TRANSCRIPTION_QUERY,
  PERSON_BY_NAME_QUERY,
} from '../utility/gql';
import {
  TalkingPointContent,
  ImageKey,
  ImageWithType,
  SidebarOption,
  AIProducerCard,
} from '../types.ts/general';
import _isEqual from 'lodash/isEqual';
import Fuse from 'fuse.js';

import {
  Showcase,
  ContentViewData,
  ExtraElementData,
  Music,
  PhotoAssetData,
  PunchListItem,
  Story,
  StoryDTO,
  Video,
  VideoVersion,
  VideoRenderingStatus,
  VolumeKeyPoint,
  VideoClip,
  AssociatedVideo,
  ShareableImageType,
  PhotoArtifactTab,
  myAudio,
  VideoAction,
  Person,
} from '../types.ts/story';
import WaveformData from 'waveform-data';

import {
  TranscriptElement,
  TranscriptData,
  fetchTranscript,
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestRemovedIndexToLeft,
  getClosestRemovedIndexToRight,
  fetchWaveformData,
  fetchWaveformDataForAudioWithTitle,
  TranscriptChange,
  test_injectTranscriptionElementsInTimeline,
  generateSubtitles,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedElementIndexToRight,
  convertFromPixels,
  mapToElementState,
  mapToSource,
} from '../videoTranscriptionProcessor/utils';
import VideoTranscriptionProcessor from '../videoTranscriptionProcessor/VideoTranscriptionProcessor';
import {
  ApiError,
  buildClient,
  Client,
  LogLevel,
  SimpleSchemaTypes,
} from '@datocms/cma-client-browser';
import { VideoRepository } from '../repositories/VideoRepository';
import { StoryRepository } from '../repositories/StoryRepository';
import { AssetRepository } from '../repositories/AssetRepository';
import { GraphQLClient } from 'graphql-request';
import { generateImagesWithGPT } from '../utility/processGPTData';
import { delay, retry } from '../utility/general';
import ChatGPTService from '../services/ChatGPTService';
import KaraokeProducer, {
  DEFAULT_KARAOKE_CONFIG,
  KaraokeConfig,
} from '../videoTranscriptionProcessor/KaraokeProducer';
import { AlbumRepository } from '../repositories/AlbumRepository';
import ApiClient from '../apiClient/ApiClient';
import { MAX_CANVAS_WIDTH } from '../components/timeline/WaveForm';
import CaptionService from '../services/CaptionService';
import {
  getElementPositionProperties,
  getOriginalVideoTrackSourceDiff,
} from '../utility/timeline';
import FadeProducer from '../fadeEffectProcessor/FadeProducer';
import { TextBrandingService } from '../components/textProcessor/TextBrandingService';
import { restoreTranscriptionFromVideoSource } from '../videoTranscriptionProcessor/VideoTranscriptionRestorer';
import { TextProducer } from '../components/textProcessor/TextProducer';
import SubtitlesProcessor from '../videoTranscriptionProcessor/SubtitlesProcessor';
import { inDebugMode } from '../utility/debug';
import { differenceBy, unionBy } from 'lodash';

export const KARAOKE_TRACK_NUMBER = 32;
const DEBUG_TRANSCRIPTION_TIMELINE = false;
const DEFAULT_PREVIEW_VIDEO_RES = 'low';
const MAX_UNDO_REDO_STACK_SIZE = 10;
const AUTOSAVE_INTERVAL = 2 * 60 * 1000; // 2 minutes

type CurrentLoadedVideo = Video & { versionId?: string } & {
  editor?: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
  ai_generated_content?: Array<{
    attributes: { prompt: string; generated_content: string };
  }>;
};

class VideoCreatorStore {
  datoClient?: Client | ApiClient;
  gqlClient?: GraphQLClient;
  renderer?: Renderer = undefined;

  videoRepository?: VideoRepository;
  storyRepository?: StoryRepository;
  assetRepository?: AssetRepository;
  albumRepository?: AlbumRepository;

  videoTranscriptionProcessor: VideoTranscriptionProcessor;
  subtitlesProcessor: SubtitlesProcessor;
  textBrandingService: TextBrandingService;
  karaokeProducer: KaraokeProducer;
  textProducer: TextProducer;

  state?: RendererState = undefined;
  stateReady = false;

  tracks?: Map<number, ElementState[]> = undefined;

  activeElementIds: string[] = [];
  selectedTrack: number = -1;

  isInitialized = false;

  isLoading = true;
  isVersionsHistoryLoading = false;
  isError = false;
  justLoaded = false;
  isSaving = false;
  savingStockPhoto = false;
  savingError?: Error;

  isPlaying = false;
  whenPaused: number | null = null;

  time = 0;
  smootherTime = 0;
  keyPointsLastUpdateTime = 0;
  isShiftKeyDown = false;

  duration = 180;

  timelineScale = 100;

  defaultTimelineScale = 100;
  maxTimelineScale = 400;
  maxCanvasScale = 400;

  isScrubbing = false;

  // startingState?: RendererState = undefined;

  subtitleLoadingStatus: 'loading' | 'loaded' | 'failed' | 'none' = 'none';
  renderingStatus = ''; //todo video.status
  renderQueueing = false;
  renderVideoResLevel:
    | 'high'
    | 'low'
    | 'default'
    | 'medium'
    | 'original'
    | null = null;
  renderingVideoIds: string[] = [];
  transcriptionLoadingStatus:
    | 'none'
    | 'loading'
    | 'failed'
    | 'loaded'
    | 'generation_failed' = 'none';

  storyId?: string;
  transcriptionId?: string;
  storyName?: string = '';

  datoContext: RenderModalCtx = {} as RenderModalCtx;

  originalVideoUrl?: string;
  originalVideoDuration: string = '0 s';
  originalVideoAudioUrl?: string;

  finalTranscriptionElements?: TranscriptElement[];
  subtitleElements?: TranscriptElement[];
  originalTranscription?: TranscriptData;

  sidebarOptions = SidebarOption.photo;
  aiProducerSubMenu: AIProducerCard = AIProducerCard.karoke_text;

  musicOptions?: Music['collection'] = undefined;
  musicProducerLoading: boolean = false;
  punchListGenerateCount: number = 0;
  uploadingMyAudio: boolean = false;

  selectedPhotoAssets = {
    tab: PhotoArtifactTab.story,
    resource: undefined,
    lastSelectedStock: undefined,
    lastSelectedAi: undefined,
    selectedId: undefined,
  } as PhotoAssetData;

  stockMusic: Music[] = [];
  organization?: Showcase = undefined;
  story?: Story = undefined;
  originalWaveForm: WaveformData | null = null;
  resampledOriginalWaveForm: WaveformData | null = null;
  maxOriginalWaveformSample: number = 0;
  audioTracksData: Record<
    string,
    | {
        waveform: WaveformData;
        originalTrackDuration: number;
        resampledWaveform?: WaveformData;
      }
    | 'loading'
  > = {};

  audioContext: AudioContext | null = null;
  audioContextReady = false;

  currentVideo?: CurrentLoadedVideo;
  currentVideoVersions?: VideoVersion[];
  currentVideoVersionsPage: number = 0;
  currentVideoVersionsTotalPages?: number;
  punchListLoading = false;
  addedPunchListItemId: string | null = null;
  fuse: any = undefined;

  unsavedShareableImages:
    | Omit<ShareableImageType, '_allReferencingSharedContents'>[]
    | null = null;

  contentStudioGeneratedContent?: ContentViewData;
  isPlayerFullScreen = false;

  karaokeLoading = false;

  isPlayheadDragging: boolean = false;
  tempDragTime: number | null = null;
  openPhotoElementReplacementModal: { element: ElementState } | null = null;
  replacementImages: {
    blog: Record<
      string,
      {
        value: ImageWithType[ImageKey] | null;
        isRemoved: boolean;
      }
    >;
    email: { value: ImageWithType[ImageKey] | null; isRemoved: boolean };
  } = { email: { value: null, isRemoved: false }, blog: {} };

  savedItemReplacementImages: {
    blogs: Record<
      number,
      Record<
        string,
        {
          value: ImageWithType[ImageKey] | null;
          isRemoved: boolean;
        }
      >
    >;
    emails: Record<
      number,
      { value: ImageWithType[ImageKey] | null; isRemoved: boolean }
    >;
  } = { emails: {}, blogs: {} };
  selectedBlogContent: {
    id?: string;
    type: 'generating' | 'generated' | 'saved';
    content?: string;
  } | null = null;

  toastState: {
    state?: 'success' | 'warning' | 'publishing' | 'loading' | 'error';
    message: string;
  } | null = null;
  showRefreshStoryForSocialProfile: boolean = false;
  pendingSharedContentIds: string[] = [];

  talkingPointContent: TalkingPointContent | null = null;
  videoLoaded: boolean = false;
  cachedAssets: Set<string> = new Set();
  timelineHeight: string = '30%';
  frameLockedTracks: number[] = [];
  timelineClipboard: {
    pos: Record<'left' | 'top', number> | null;
    action: 'copy' | 'copied' | 'paste';
    copied: {
      element: ElementState;
      data: Record<'time' | 'track', number> | null;
    } | null;
    tmp: {
      element: ElementState;
    };
    secondaryActions?: 'clearVolumeKeyPoints'[];
  } | null = null;
  videoClipPreview: (VideoClip & { autoPlay?: boolean }) | null = null;
  selectedVolumeKeyPoint?: VolumeKeyPoint | null;
  currentEditor: string = 'Unknown';
  currentUserType: 'internal' | 'external' = 'internal';

  videoSnapshots: Array<CurrentLoadedVideo & { label?: string }> = [];
  redoSnapshots: Array<CurrentLoadedVideo & { label?: string }> = [];

  undoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
    isSaved?: boolean;
  }[] = [];
  redoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
    isSaved?: boolean;
  }[] = [];

  resetUndoRedo() {
    this.undoStack = [];
    this.redoStack = [];
    this.videoTranscriptionProcessor.resetUndoRedo();
    this.videoSnapshots = [];
    this.redoSnapshots = [];
  }

  undo() {
    if (this.undoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.undoStack.pop()!;
    undoCommand();
    this.redoStack.push({ undoCommand, redoCommand });
  }

  redo() {
    if (this.redoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.redoStack.pop()!;
    redoCommand();
    this.undoStack.push({ undoCommand, redoCommand });
  }

  //todo refactor
  photoDataForDato: Record<
    string,
    {
      type: 'quotes' | 'stock' | 'ai' | 'artifact';
      url: string;
      title?: string;
      alt?: string;
      cat?: string;
    } & (
      | {
          fileName: string;
        }
      | { uploadId: string }
    )
  > = {};

  punchListData: PunchListItem[] | null = null;

  refreshStory: number = 0;
  disableAiImageGenerate = false;

  constructor() {
    makeAutoObservable(this, {
      canRedo: computed,
      canUndo: computed,
    });
    this.videoTranscriptionProcessor = new VideoTranscriptionProcessor();
    this.karaokeProducer = new KaraokeProducer();
    this.subtitlesProcessor = new SubtitlesProcessor((elements) => {
      console.log('subtitle elements updated', elements);
      this.subtitleElements = elements;
      this.karaokeProducer.setSubtitleElements(elements);
    });
    this.textBrandingService = new TextBrandingService();
    this.textProducer = new TextProducer();
    this.videoTranscriptionProcessor.onFinalTranscriptionElementsChange = (
      elements,
    ) => {
      this.finalTranscriptionElements = elements;
      this.karaokeProducer.setTranscriptionElements(elements);
    };
  }

  async initializeWithStory(
    storyId: string,
    videoId?: string | null,
    showcaseSlug?: string | null,
  ) {
    if (!this.storyRepository)
      throw new Error('Story repository is not initialized');
    const story = await this.findOneStory(storyId);

    if (!story) throw new Error('Story not found. Cannot initialize data.');
    this.story = story;

    const organization = showcaseSlug
      ? story._allReferencingShowcases.find((s) => (s.slug = showcaseSlug))
      : story._allReferencingShowcases[0];
    this.organization = organization;

    this.storyId = story.id;
    this.storyName = story.title;
    this.originalVideoUrl = story.originalVideo.url;
    this.originalVideoAudioUrl = story.transcription?.audio?.url;
    this.originalVideoDuration = `${story?.originalVideo.video?.duration} s`;
    (this.transcriptionId = story.transcription?.elementsJson?.id),
      (this.isInitialized = true);

    // this.createAudioContext(0);
    if (!this.transcriptionId) {
      setTimeout(this.pollTranscriptionId.bind(this), 10000);
    }

    if (
      videoId &&
      this.story?.otherVideos?.find(
        (v) =>
          v.id === videoId ||
          v.associatedVideos.find((av) => av.id === videoId),
      )
    ) {
      await this.loadVideo(videoId);
    } else if (story.finalVideo?.id) {
      await this.loadVideo(story.finalVideo?.id);
    } else if (this.story?.otherVideos?.length) {
      await this.loadVideo(this.story.otherVideos[0].id!);
    } else {
      await this.createNewVideoFromSource();
    }
    await this.loadTranscription();
    await this.loadOriginalWaveformData();

    this.renderingVideoIds = story.otherVideos
      .flatMap((v) => [v, ...v.associatedVideos])
      .filter((v) => v.videoStatus === 'rendering')
      .map((v) => v.id!);
  }

  async initializeWithShowcase(showcaseSlug: string) {
    if (!this.albumRepository)
      throw new Error('Album repository is not initialized');

    const showcase = await this.albumRepository.findOneBySlug(showcaseSlug);
    if (!showcase)
      throw new Error('Showcase not found. Cannot initialize data.');
    this.organization = showcase;
    const storyWithVideo = showcase.stories.find((s) => s.originalVideo);
    if (storyWithVideo) {
      await this.initializeWithStory(storyWithVideo.id, null, showcaseSlug);
    }
  }

  async initializeData(data: {
    storyId: string;
    videoId?: string | null;
    showcaseSlug?: string | null;
  }) {
    const { storyId, videoId, showcaseSlug } = data;

    if (!storyId && !showcaseSlug)
      throw new Error('Story id or showcase id are required');

    if (storyId) {
      await this.initializeWithStory(storyId, videoId, showcaseSlug);
    } else if (showcaseSlug) {
      await this.initializeWithShowcase(showcaseSlug);
    }
  }

  linkFunctions(ctx: RenderModalCtx & { clerkUserSessionToken?: string }) {
    runInAction(() => (this.datoContext = ctx)); // TODO: try without runInAction
    if (ctx.currentUserAccessToken) {
      runInAction(() => {
        this.datoClient = buildClient({
          apiToken: this.datoContext.currentUserAccessToken || null,
          environment: this.datoContext.environment,
          logLevel: LogLevel.NONE,
        });
      });
    } else {
      runInAction(() => {
        this.datoClient = new ApiClient({
          environment: this.datoContext.environment,
          authToken: ctx.clerkUserSessionToken || null,
        });
      });
    }

    runInAction(
      () =>
        (this.gqlClient = createGQLClient({
          includeDrafts: true,
          excludeInvalid: true,
          environment: this.datoContext.environment,
          authToken: process.env.REACT_APP_DATOCMS_READ_API_TOKEN!, //  this.datoContext.currentUserAccessToken!,
        })),
    );
    runInAction(
      () =>
        (this.videoRepository = new VideoRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
    runInAction(
      () =>
        (this.storyRepository = new StoryRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
    runInAction(
      () =>
        (this.assetRepository = new AssetRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
    runInAction(
      () =>
        (this.albumRepository = new AlbumRepository(
          this.datoClient!,
          this.gqlClient!,
        )),
    );
  }

  async setCurrentVersionVideo(versionId: string) {
    const videoVersion = this.currentVideoVersions?.find(
      (videoVersion) => videoVersion.versionId === versionId,
    );
    if (videoVersion) {
      await this.renderVideo(videoVersion, false, DEFAULT_PREVIEW_VIDEO_RES);
      this.textBrandingService.loadDefaultTemplateOnVideoLoad();
      this.resetUndoRedo();
    }
  }

  async restoreVideoVersion(videoVersionId: string) {
    const itemVersions =
      await this.datoClient?.itemVersions.restore(videoVersionId);
    this.setCurrentVersionVideo(
      (
        itemVersions?.[0] as unknown as Video & {
          versionId: string;
        }
      ).versionId,
    );
  }

  async cacheVideoSource(sourceUrl: string) {
    if (!this.renderer) {
      throw new Error('Renderer is not initialized');
    }

    console.log('Downloading media', sourceUrl);
    let blob = await fetch(sourceUrl).then((r) => r.blob());
    console.log('Media downloaded', sourceUrl);
    await this.renderer.cacheAsset(sourceUrl, blob);
    console.log('Video source cached', sourceUrl);
  }

  async loadCurrentVideoVersionsHistory(page: number = 0) {
    return this.loadVersionsHistory(this.currentVideo!.id!, page);
  }

  async loadVersionsHistory(videoId: string, page: number = 0) {
    if (!this.videoRepository) {
      throw new Error('Video repository is not initialized');
    }
    try {
      this.isVersionsHistoryLoading = true;
      const pageSize = this.currentUserType === 'internal' ? 5 : 1;
      const videoVersions = await this.videoRepository!.getVersionsByVideoId(
        videoId,
        pageSize,
        page,
      );
      runInAction(() => {
        if (videoVersions.length > 0) {
          this.currentVideoVersions = videoVersions;
          this.currentVideoVersionsPage = page;
          const currentVideoVersion = this.currentVideoVersions!.find(
            (videoVersion) => videoVersion.meta.is_current,
          )!;
          if (this.currentVideo && currentVideoVersion) {
            this.currentVideo!.versionId = currentVideoVersion.versionId;
          }
        }
        if (videoVersions.length < pageSize) {
          this.currentVideoVersionsTotalPages =
            this.currentVideoVersionsPage + 1;
        }
      });
    } catch (err) {
      throw err;
    } finally {
      this.isVersionsHistoryLoading = false;
    }
  }

  async loadVideo(videoId: string, resetTimeline = true) {
    if (!this.videoRepository) {
      throw new Error('Video repository is not initialized');
    }

    this.isLoading = true;
    this.isError = false;
    // await until queued and saved
    while (this.renderQueueing || this.isSaving) {
      await delay(500);
    }
    this.renderVideoResLevel = null;
    try {
      await this.loadVersionsHistory(videoId);
      this.currentVideoVersionsTotalPages = undefined;
      const currentVideoVersion = this.currentVideoVersions!.find(
        (videoVersion) => videoVersion.meta.is_current,
      )!; //todo handle error
      // debugger;

      await this.renderVideo(
        currentVideoVersion,
        resetTimeline,
        DEFAULT_PREVIEW_VIDEO_RES,
      );
      this.textBrandingService.loadDefaultTemplateOnVideoLoad();
      this.resetUndoRedo();
      console.log('Video loaded');
    } catch (err) {
      console.log('error loading video', err);
      console.log('loadVideo: Set is Loading FALSE');
      this.isLoading = false;
      this.isError = true;
    } finally {
      // this.isLoading = false;
    }
  }

  async loadVideoWithoutRendering(videoId: string, loadElements = false) {
    videoCreator.currentVideoVersions =
      await videoCreator.videoRepository?.getVersionsByVideoId(videoId, 1);

    const currentVideoVersion = videoCreator.currentVideoVersions?.find(
      (videoVersion) => videoVersion.meta.is_current,
    )!;

    if (!currentVideoVersion.videoSource.height) {
      const height =
        this.story?.finalVideo?.videoFilePrimary?.height ||
        this.story?.originalVideo?.height;
      currentVideoVersion.videoSource.height = height;
    }
    this.currentVideo = currentVideoVersion;
    this.optimizeCurrentVideoSource(DEFAULT_PREVIEW_VIDEO_RES);
    if (loadElements) {
      await this.loadVideoElementsWithoutRendering(this.currentVideo);
    }
  }

  abortIfNotReady() {
    if (!this.isVideoCreatorReady) {
      throw new Error('Video creator is not ready');
    }
  }

  async loadVideoWithAspectRatio(aspectRatio: Video['aspectRatio']) {
    this.abortIfNotReady();
    const parentVideo = this.currentVideo!.parentVideo || this.currentVideo;
    const currentAspectRatio = this.currentVideo!.aspectRatio;
    const associatedVideo =
      parentVideo?.aspectRatio === aspectRatio
        ? parentVideo
        : parentVideo!.associatedVideos.find(
            (video) => video.aspectRatio === aspectRatio,
          );

    if (associatedVideo) {
      await this.loadVideo(associatedVideo.id!);
      return;
    }

    // todo copy parent
    this.isLoading = true;
    await this.copyCurrentVideo(this.currentVideo!.title);
    // parentVideo!.associatedVideos.push(this.currentVideo!);
    this.currentVideo!.aspectRatio = aspectRatio;
    this.currentVideo!.parentVideo = parentVideo;
    const [w, h] = aspectRatio.split(':').map((n) => parseInt(n));
    const width = this.currentVideo!.videoSource.height * (w / h);
    this.currentVideo!.videoSource.width = width;
    await this.adjustLogoElements(currentAspectRatio, aspectRatio);
    this.optimizeCurrentVideoSource(DEFAULT_PREVIEW_VIDEO_RES);
    await this.renderer?.setSource(this.currentVideo!.videoSource);
    this.textBrandingService.loadDefaultTemplateOnVideoLoad();
  }

  private async adjustLogoElements(
    currentAspectRatio: '16:9' | '1:1' | '9:16',
    nextAspectRatio: '16:9' | '1:1' | '9:16',
  ) {
    function aspectRatioDimension(aspectRatio: '16:9' | '1:1' | '9:16') {
      if (aspectRatio === '16:9') {
        return {
          x: 94,
          y: 4,
          width: 12,
        };
      } else if (aspectRatio === '9:16') {
        return {
          x: 92,
          y: 5,
          width: 25,
        };
      }
      return {
        x: 95,
        y: 5,
        width: 19,
      };
    }

    const logoElementIndices = this.currentVideo!.videoSource.elements.reduce(
      (acc: number[], el: Record<string, any>, index: number) => {
        if (this.isLogoElement({ source: el } as ElementState)) {
          // include logo elements that weren't changed
          // see src/components/sidepanel/ArtifactsAndAssets.tsx:167
          return acc.concat(index);
        }
        return acc;
      },
      [],
    );

    for (const index of logoElementIndices) {
      const logoEl = this.currentVideo!.videoSource.elements[index];

      let sourceAspectRatio = 1;
      for (const album of this?.story?._allReferencingShowcases || []) {
        const logo = (album.organizationLogos || []).find(
          (a) => a.responsiveImage?.src === logoEl.source,
        );
        if (logo?.width && logo?.height) {
          sourceAspectRatio = parseFloat(logo.width) / parseFloat(logo.height);
          break;
        }
      }
      const objectData = aspectRatioDimension(currentAspectRatio);

      function calculatePosX(defaultValue: number, width: number) {
        const posX = parseFloat(logoEl.x) - parseFloat(logoEl.width);
        const pos = parseFloat(logoEl.x);
        if (pos === objectData.x) return `${defaultValue}%`;

        if (pos >= 50) return `${(defaultValue / objectData.x) * pos}%`;
        return `${Math.max(
          (defaultValue / objectData.x) * pos,
          width + posX * (defaultValue / objectData.x),
        )}%`;
      }

      function calculatePosY(defaultValue: number) {
        const value =
          ((100 - defaultValue) / (100 - objectData.y)) * parseFloat(logoEl.y);
        return `${value}%`;
      }

      if (nextAspectRatio === '16:9') {
        const width = (12 / objectData.width) * parseFloat(logoEl.width);

        logoEl.x = calculatePosX(94, width);
        logoEl.y = calculatePosY(4);
        logoEl.width = `${width}%`;
        logoEl.height = `${(width / sourceAspectRatio) * (16 / 9)}%`;
      } else if (nextAspectRatio === '9:16') {
        const width = (25 / objectData.width) * parseFloat(logoEl.width);

        logoEl.x = calculatePosX(92, width);
        logoEl.y = calculatePosY(5);
        logoEl.width = `${width}%`;
        logoEl.height = `${(width / sourceAspectRatio) * (9 / 16)}%`;
      } else if (nextAspectRatio === '1:1') {
        const width = (19 / objectData.width) * parseFloat(logoEl.width);

        logoEl.x = calculatePosX(95, width);
        logoEl.y = calculatePosY(5);
        logoEl.width = `${width}%`;
        logoEl.height = `${width / sourceAspectRatio}%`;
      }
    }
  }

  async copyVideo(
    videoId: string,
    newTitle: string,
    sourcePlatform: Video['sourcePlatform'],
  ) {
    await this.loadVideo(videoId);
    await this.copyCurrentVideo(newTitle);

    this.currentVideo!.associatedVideos = [];
    delete this.currentVideo!.videoFilePrimary;
    this.currentVideo!.videoStatus = 'editing';
    this.currentVideo!.sourcePlatform = sourcePlatform;
  }

  async createNewVideoFromSource(
    newTitle?: string,
    newSource?: any,
    transcriptionChanges?: TranscriptChange[],
    transcriptionSnapshot?: Pick<TranscriptData, 'elements'>,
    extraElementData?: Video['extraElementData'],
    sourcePlatform: 'creator-studio' | 'content-studio' = 'creator-studio',
  ) {
    // console.log('creating new video');
    const video: Video = {
      title: newTitle || this.story!.title,
      videoSource:
        newSource ||
        this.getDefaultSource({ videoRes: DEFAULT_PREVIEW_VIDEO_RES }),
      videoStatus: 'editing',
      extraElementData: extraElementData || {},
      transcriptionChanges: transcriptionChanges || [],
      transcriptionSnapshot: transcriptionSnapshot,
      associatedVideos: [],
      aspectRatio: '16:9',
      sourcePlatform,
    };
    this.currentVideoVersions = [];
    await this.renderVideo(video, true, DEFAULT_PREVIEW_VIDEO_RES);
    this.resetUndoRedo();
  }

  async createNewVideoForContentClip(
    originalVideo: Pick<
      Video,
      | 'title'
      | 'videoSource'
      | 'extraElementData'
      | 'transcriptionChanges'
      | 'transcriptionSnapshot'
    >,
    sourcePlatform: 'creator-studio' | 'content-studio',
    clip: any,
  ) {
    debugger;
    const newTitle = `${originalVideo.title} - ${clip.theme}`;

    const source =
      deepClone(originalVideo.videoSource) || this.getDefaultSource();
    let elements = source.elements;
    if (elements.every((e: any) => e.type !== 'video')) {
      elements = elements.concat({
        ...this.getDefaultSource()?.elements[0],
        duration: clip.duration,
        trim_start: clip.startTime,
      });
    }

    const video = {
      title: newTitle || this.story!.title,
      videoSource: {
        ...source,
        duration: clip.duration,
        elements: elements.map(mapToElementState),
      },
      videoStatus: 'editing',
      extraElementData: originalVideo.extraElementData,
      transcriptionChanges: originalVideo.transcriptionChanges,
      transcriptionSnapshot: originalVideo.transcriptionSnapshot,
      associatedVideos: [],
      aspectRatio: '16:9',
      sourcePlatform,
    } as CurrentLoadedVideo;
    this.currentVideo = video;
    this.loadVideoElementsWithoutRendering(video);

    const noRendererOutput = {
      duration: clip.duration,
      source: this.currentVideo.videoSource,
    };
    await this.videoTranscriptionProcessor.cropVideoToKeepTextElements(
      clip.transcriptPosition.startIndex,
      clip.transcriptPosition.endIndex,
      noRendererOutput,
    );
    debugger;
    const sourceNew = {
      ...noRendererOutput.source,
      ...mapToSource({ elements: noRendererOutput.source.elements }),
    };
    this.currentVideo.videoSource = sourceNew;
    this.createUndoPointForTranscription();
  }

  async copyCurrentVideo(newTitle: string) {
    // console.log('copying video');
    const video = toJS(this.currentVideo)!;
    delete video.id;
    video.title = newTitle;
    this.currentVideoVersions = [];
    await this.renderVideo(video, true, DEFAULT_PREVIEW_VIDEO_RES);
    this.resetUndoRedo();
  }

  async renameVideo(videoId: string, newTitle: string, withRenderer = true) {
    console.log('renaming video', videoId);

    if (videoId === 'original_story') {
      await this.updateStory({
        id: this.story!.id,
        title: newTitle,
      });
      this.story!.title = newTitle;
      return;
    }

    if (videoId && this.currentVideo?.id === videoId) {
      this.currentVideo!.title = newTitle;
      await this.saveCurrentAndParentVideos(withRenderer);
    } else if (videoId) {
      await this.videoRepository?.updateVideo({ id: videoId, title: newTitle });
      if (this.currentVideo?.parentVideo?.id === videoId) {
        this.currentVideo!.parentVideo!.title = newTitle;
      }
    } else if (this.currentVideo?.id === videoId) {
      this.currentVideo!.title = newTitle;
      await this.saveStoryAndVideo(false, withRenderer);
    }

    const updatedVideos = (this.story?.otherVideos?.map((v) => {
      if (videoId === v.id) return { ...v, title: newTitle };
      return v;
    }) || []) as Video[];
    this.story!.otherVideos = updatedVideos;

    // this.resetUndoRedo();
  }

  async hideVideo(videoId?: string) {
    if (videoId === 'original_story' || !videoId) {
      return;
    }
    await this.updateVideoField('isHidden', true, videoId);
  }

  async updateIsClientReady(isClientReady: boolean, videoId?: string) {
    if (videoId === 'original_story' || !videoId) {
      return;
    }
    await this.updateVideoField('isClientReady', isClientReady, videoId);
  }

  private async updateVideoField<K extends keyof AssociatedVideo>(
    key: K,
    value: Video[K],
    videoId: string,
  ) {
    try {
      if (this.currentVideo?.id === videoId) {
        this.currentVideo![key] = value;
        await this.saveCurrentAndParentVideos(false, false);
      } else {
        await this.videoRepository?.updateVideo({
          id: videoId,
          [key]: value,
        });
        if (this.currentVideo?.parentVideo?.id === videoId) {
          this.currentVideo!.parentVideo![key] = value;
        }
      }
    } catch (err) {
      console.error(
        `Failed updating video ${videoId} field [${key}]: ${value}`,
        err,
      );
      return;
    }

    const updatedVideos = (this.story?.otherVideos?.map((v) => {
      if (videoId === v.id) return { ...v, [key]: value };
      return v;
    }) || []) as Video[];
    this.story!.otherVideos = updatedVideos;
  }

  private lockVideoElement() {
    // //debugger;
    const videoElement = this.currentVideo?.videoSource.elements.find(
      (el: any) => el.type === 'video',
    );
    if (videoElement) {
      console.log('locking video element');
      videoElement.locked = true;
    }
  }

  private unlockVideoElement() {
    // //debugger;
    console.log('this.video', this.currentVideo);
    const videoElement = this.currentVideo?.videoSource?.elements?.find(
      (el: any) => el.type === 'video',
    );
    if (videoElement) {
      console.log('unlocking video element');
      videoElement.locked = false;
    }
  }

  private updateCurrentRenderingStatus() {
    this.renderingStatus = this.currentVideo?.videoStatus || 'none';
    if (this.renderingStatus === 'rendering') {
      setTimeout(this.pollRenderingStatus.bind(this), 5000);
    }
  }

  isOriginalVideoElement(elementSource: any) {
    return (
      elementSource.type === 'video' &&
      (elementSource.source === this.originalVideoUrl ||
        elementSource.source.includes(
          this.story!.originalVideo.video.muxPlaybackId,
        ))
    );
  }

  private optimizeCurrentVideoSource(level: 'high' | 'low' | 'medium') {
    if (!this.currentVideo || !this.story) {
      throw new Error(
        'Current video or story is not initialized to optimize video source',
      );
    }
    this.replaceVideoSourceUrl(this.currentVideo.videoSource, level);
  }

  private getDimensionsForResLevel(
    currentDimensions: { width: number; height: number },
    resLevel: 'high' | 'low' | 'medium' | 'original',
  ) {
    const originalVideoHeight = this.story!.originalVideo.height || 720;
    const { width, height } = this.story!.originalVideo; //currentDimensions;
    let newWidth, newHeight, scaleFactor;
    if (resLevel === 'high') {
      scaleFactor = Math.max(1, originalVideoHeight / 720);
    } else if (resLevel === 'low') {
      scaleFactor = Math.max(1, originalVideoHeight / 270);
    } else if (resLevel === 'medium') {
      scaleFactor = Math.max(1, originalVideoHeight / 480);
    } else {
      scaleFactor = 1;
    }
    newWidth = Math.ceil(width / scaleFactor);
    newHeight = Math.ceil(height / scaleFactor);
    return { width: newWidth, height: newHeight };
  }

  private getVideoSourceUrlForResLevel(
    resLevel: 'high' | 'low' | 'medium' | 'original',
  ) {
    if (!this.story) {
      throw new Error(
        'Current video or story is not initialized to optimize video source',
      );
    }
    let sourceVideoUrl;

    if (resLevel === 'high') {
      sourceVideoUrl = this.story.originalVideo.video.mp4Url;
    } else if (resLevel === 'low') {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlLow;
    } else if (resLevel === 'medium') {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlMedium;
    }

    if (!sourceVideoUrl) {
      console.error('No video source found for optimization, using original');
      sourceVideoUrl = this.story.originalVideo.url;
    }
    return sourceVideoUrl;
  }

  private replaceVideoSourceUrl(
    source: any,
    resLevel: 'high' | 'low' | 'medium' | 'original',
  ) {
    const videoSourceUrl = this.getVideoSourceUrlForResLevel(resLevel);
    source.elements.forEach((element: any) => {
      if (this.isOriginalVideoElement(element)) {
        element.source = videoSourceUrl;
      }
    });
    return source;
  }

  private async renderVideo(
    video: CurrentLoadedVideo,
    resetTimeline = true,
    resLevel: 'high' | 'low' | 'medium' = 'medium',
  ) {
    this.currentVideo = video;
    console.log('load video', video);
    this.updateCurrentRenderingStatus();
    // this.lockVideoElement();
    this.unlockVideoElement();
    this.punchListData = this.currentVideo!.punchList || [];
    this.loadVideoElementsWithoutRendering(video);

    // render video
    this.optimizeCurrentVideoSource(resLevel);
    const videoSourceUrl = this.getVideoSourceUrlForResLevel(resLevel);

    if (this.renderer?.ready && this.currentVideo) {
      if (resetTimeline) {
        this.stateReady = false;
      }
      if (!this.cachedAssets.has(videoSourceUrl)) {
        await this.cacheVideoSource(videoSourceUrl);
        this.cachedAssets.add(videoSourceUrl);
      }
      await this.renderer?.setSource(this.currentVideo.videoSource);

      const fadeProducer = new FadeProducer();
      await fadeProducer.tidyOriginalVideoOverlay();
      if (resetTimeline) {
        this.setTime(0, true);
      }
    }
  }

  private async loadVideoElementsWithoutRendering(video: CurrentLoadedVideo) {
    // apply transcription changes
    if (video.transcriptionSnapshot?.elements) {
      console.log(
        'loadVideoElementsWithoutRendering: setting transcription snapshot',
      );
      this.videoTranscriptionProcessor.setTranscriptionSnapshot(
        toJS(video.transcriptionSnapshot.elements),
      );
      if (video.transcriptionChanges?.length) {
        console.log(
          'loadVideoElementsWithoutRendering: apply changes to snapshot',
        );
        this.videoTranscriptionProcessor.applyChangesToCurrentTranscription(
          toJS(video.transcriptionChanges) || [],
        );
      }
    } else if (this.finalTranscriptionElements) {
      console.log('loadVideoElementsWithoutRendering: apply changes');
      this.videoTranscriptionProcessor.applyChangesToOriginalTranscription(
        toJS(this.currentVideo!.transcriptionChanges) || [],
      );
    }
    this.subtitlesProcessor.setSubtitleElements(
      this.getVideoSubtitleElements(),
    );
    this.videoTranscriptionProcessor.setOriginalSource(video.videoSource);
    this.karaokeProducer.setKaraokeElementsFromSource(video.videoSource);

    const defaultKaraokeConfig = {
      ...DEFAULT_KARAOKE_CONFIG,
      ...this.karaokeProducer.getKaraokeTextSettingByAspectRatio(),
    };

    const config = (video.extraElementData.karaokeConfig ??
      defaultKaraokeConfig) as KaraokeConfig;

    this.karaokeProducer.setConfig(config);
  }

  async saveCurrentStory() {
    if (!this.story) return;
    if (!this.storyRepository) {
      throw new Error('Story repository is not initialized');
    }
    try {
      const storyDTO = await this.attachPunchListPhotosToStory();
      await this.updateStory(storyDTO);
      this.photoDataForDato = {};
    } catch (err) {
      console.log('error saving story', err);
    }
  }

  async saveStoryAndVideo(
    asFinal: boolean = false,
    withRenderer = true,
    resetTimeline = true,
    autoSave = false,
  ) {
    await this.saveCurrentAndParentVideos(
      withRenderer,
      resetTimeline,
      autoSave,
    );
    if (asFinal) {
      this.story!.finalVideo =
        this.currentVideo?.parentVideo ?? this.currentVideo;
    }
    await this.saveCurrentStory();
  }

  prepareCurrentVideoForSave = (withRenderer = true) => {
    if (!this.currentVideo) return;
    if (withRenderer) {
      const videoSource = this.renderer!.getSource();
      if (!videoSource.elements) {
        throw { humanMessage: 'Video is not yet loaded' };
      }
      this.currentVideo.videoSource = videoSource;
      if (this.currentVideo.clipJson?.duration !== this.duration) {
        this.currentVideo.clipJson = {
          ...(this.currentVideo.clipJson || {}),
          duration: this.duration,
        };
        for (const storyVideo of this.story?.otherVideos || []) {
          if (storyVideo.id === this.currentVideo.id) {
            storyVideo.clipJson = {
              ...(storyVideo.clipJson || {}),
              duration: this.duration,
            };
          }
        }
      }
    }
    if (this.story?.byExternalUser) {
      this.currentVideo.isClientReady = true;
    }

    this.currentVideo.extraElementData.karaokeConfig =
      this.karaokeProducer.getKaraokeConfig();
    this.currentVideo.transcriptionChanges =
      this.videoTranscriptionProcessor.getTranscriptionChanges();

    const extraElementData = this.currentVideo.extraElementData || {};
    for (let element of Object.values(extraElementData)) {
      delete (element as ExtraElementData)?.punchListData;
    }

    this.currentVideo.punchList?.forEach((punchListItem) => {
      extraElementData[punchListItem.id!] = {
        ...(extraElementData[punchListItem.id!] || {}),
        punchListData: punchListItem,
      };
    });
    this.currentVideo.extraElementData = extraElementData;
  };

  async saveCurrentAndParentVideos(
    withRenderer = true,
    resetTimeline = true,
    autoSave = false,
  ) {
    console.log('save video', this.currentVideo);
    // Early returns for exceptional cases
    if (
      !this.currentVideo ||
      !this.story ||
      !this.videoRepository ||
      !this.storyRepository
    ) {
      console.error('Invalid input or repositories not initialized');
      return;
    }

    this.isSaving = true;
    this.savingError = undefined;
    let savedVideoId;

    try {
      // Update current video properties
      this.prepareCurrentVideoForSave(withRenderer);
      this.addVideoAction({
        editor: this.currentEditor,
        date: new Date().getTime(),
        type: autoSave ? 'autosave' : 'save',
      });
      // Save or update current video
      savedVideoId = await this.videoRepository.saveOrUpdateVideo({
        ...this.currentVideo,
        transcriptionText:
          this.videoTranscriptionProcessor.getFinalTranscriptionText(),
        transcriptionSnapshot: {
          elements:
            this.videoTranscriptionProcessor.finalTranscriptionElements!,
        },
        subtitles: this.subtitlesProcessor.getSubtitleLines(),
      });
      this.currentVideo._publishedAt = new Date().toISOString();

      if (!this.currentVideo.id) {
        this.currentVideo.id = savedVideoId;

        // Handle parent video or story update (TODO move to saveStoryAndVideo ?)
        if (this.currentVideo.parentVideo) {
          await this.addCurrentToParentVideos();
        } else {
          await this.addCurrentToStoryVideos();
        }
      }
      await this.loadCurrentVideoVersionsHistory(0);
      this.currentVideoVersionsTotalPages = undefined;
    } catch (error) {
      console.error('Error saving video', error);
      this.savingError = error as ApiError;
    } finally {
      this.isSaving = false;
    }

    // Load saved video if available
    // if (savedVideoId && withRenderer && !this.renderQueueing) {
    //   this.currentVideo.id = savedVideoId;
    //   // await this.loadVideo(savedVideoId, resetTimeline);
    // }
  }

  // Helper methods for saveCurrentAndParentVideos
  async addCurrentToParentVideos() {
    this.currentVideo!.parentVideo!.associatedVideos.push(this.currentVideo!);
    this.currentVideo!.parentVideo!.id =
      await this.videoRepository!.saveOrUpdateVideo(
        this.currentVideo!.parentVideo!,
      );
    const parentStoryVideo = this.story!.otherVideos.find(
      (v) => v.id === this.currentVideo!.parentVideo!.id,
    );
    if (parentStoryVideo) {
      parentStoryVideo.associatedVideos.push(this.currentVideo!);
    }
  }

  // Helper methods for saveCurrentAndParentVideos
  async addCurrentToStoryVideos() {
    if (!this.currentVideo?.id) {
      throw new Error('Current video id is not set');
    }
    await this.storyRepository!.addVideoToStory(
      this.story!.id,
      this.currentVideo!.id!,
    );
    this.story!.otherVideos = [this.currentVideo!, ...this.story!.otherVideos];
  }

  async removeStoryVideo(videoId?: string) {
    if (videoId) {
      await this.storyRepository!.removeVideoFromStory(this.story!.id, videoId);
    }
    // remove video from list of other videos
    videoCreator.story!.otherVideos = videoCreator.story!.otherVideos.filter(
      (v) => v.id !== videoId,
    );
  }

  async loadOriginalWaveformData() {
    if (!this.story?.transcription?.waveformData) return;
    try {
      const waveformData = await fetchWaveformData(
        this.story!.transcription!.waveformData!.id,
      );
      this.originalWaveForm = WaveformData.create(waveformData);
      this.maxTimelineScale = Math.min(
        this.maxTimelineScale,
        Math.floor(
          this.originalWaveForm.sample_rate / this.originalWaveForm.scale,
        ),
      );

      this.timelineScale = Math.min(this.timelineScale, this.maxTimelineScale);

      let maxSample = 0;
      const max_array = this.originalWaveForm.channel(0).max_array();
      for (let i = 0; i < max_array.length; i++) {
        if (max_array[i] > maxSample) {
          maxSample = max_array[i];
        }
      }
      this.maxOriginalWaveformSample = maxSample;

      // console.log('Waveform loaded, channels:', this.originalWaveForm.channels);
    } catch (e) {
      console.log('loadOriginalWaveformData error', e);
      throw e;
    }
  }

  async loadTranscription() {
    console.log('loading transcription');
    if (!this.transcriptionId) {
      if (this.story?.transcription?.jobStatus === 'FAILED') {
        this.transcriptionLoadingStatus = 'generation_failed';
      }
      return;
    }
    if (!this.currentVideo)
      throw new Error(
        'Current video must be initialized before loading transcription',
      );
    try {
      if (!this.currentVideo.transcriptionSnapshot?.elements) {
        this.transcriptionLoadingStatus = 'loading';
      }

      const transcript = await fetchTranscript(this.transcriptionId);
      this.originalTranscription = transcript;
      // this.initializeFuse(transcript.elements);

      this.videoTranscriptionProcessor.setTranscriptionElements(
        structuredClone(transcript.elements),
      );

      if (!this.currentVideo.transcriptionSnapshot?.elements) {
        console.log('loadTranscription: applying transcription changes');
        this.videoTranscriptionProcessor.applyChangesToOriginalTranscription(
          toJS(this.currentVideo.transcriptionChanges || []) || [],
        );
      }
      this.transcriptionLoadingStatus = 'loaded';
    } catch (e) {
      this.transcriptionLoadingStatus = 'failed';
      console.log('loadTranscription error', e);
      throw e;
    }
  }

  async regenerateTranscription() {
    this.transcriptionId = '';
    try {
      await this.saveCurrentStory();
      await this.storyRepository!.deleteTranscription(this.story!.id);
      this.story!.transcription = undefined;
      this.finalTranscriptionElements = undefined;
      this.originalVideoAudioUrl = '';
      this.originalWaveForm = null;
      this.transcriptionLoadingStatus = 'loaded';
      this.pollTranscriptionId();
    } catch (err) {
      this.transcriptionLoadingStatus = 'failed';
    }
  }

  async requestSubtitlesForCurrentVideo() {
    if (!this.currentVideo || !this.finalTranscriptionElements) {
      throw new Error(
        'Current video or transcription elements not initialized',
      );
    }
    const transcriptionLanguage = this.originalTranscription!.language;
    try {
      this.subtitleLoadingStatus = 'loading';
      const subtitles = await generateSubtitles(
        this.finalTranscriptionElements.filter(
          (el) =>
            el.state !== 'removed' &&
            el.state !== 'cut' &&
            el.state !== 'muted',
        ),
        transcriptionLanguage,
      );
      debugger;
      if (subtitles) {
        this.currentVideo.subtitles = subtitles;
        this.subtitlesProcessor.setSubtitleElements(
          this.getVideoSubtitleElements(),
        );
        this.subtitleLoadingStatus = 'loaded';
      }
      return subtitles;
    } catch (err) {
      console.log('error requesting subtitles', err);
      this.subtitleLoadingStatus = 'failed';
      return null;
    }
  }

  initializeFuse(transcriptElements: TranscriptElement[]) {
    // find start and stop index of every sentence in the elements array
    let sentences: any = [];
    let sentenceStart = 0;
    let sentenceEnd = 0;
    let sentenceText = '';
    let sentenceStartTime = 0;
    let sentenceEndTime = 0;
    let sentenceDuration = 0;
    transcriptElements.forEach((el: any, index: number) => {
      sentenceText += el.value || '';

      if (el.type === 'text') {
        sentenceEndTime = el.end_ts;
        sentenceDuration = sentenceEndTime - sentenceStartTime;
      }
      if (el.value === '.' || el.value === '?' || el.value === '!') {
        sentenceEnd = index;
        sentences.push({
          text: sentenceText,
          startIndex: sentenceStart,
          endIndex: sentenceEnd,
          startTime: sentenceStartTime,
          endTime: sentenceEndTime,
          duration: sentenceDuration,
        });
        sentenceText = '';
        sentenceStart = index + 1;
        sentenceStartTime = el.end_ts;
      }
    });

    this.fuse = new Fuse(sentences, {
      includeScore: true,
      keys: ['text'],
    });
  }

  getSentences(transcriptElements: TranscriptElement[]) {
    // find start and stop index of every sentence in the elements array
    let sentences: any = [];
    let sentenceStart = 0;
    let sentenceEnd = 0;
    let sentenceText = '';
    let sentenceStartTime = 0;
    let sentenceEndTime = 0;
    let sentenceDuration = 0;
    transcriptElements.forEach((el: any, index: number) => {
      sentenceText += el.value || '';

      if (el.type === 'text') {
        sentenceEndTime = el.end_ts;
        sentenceDuration = sentenceEndTime - sentenceStartTime;
      }
      if (el.value === '.' || el.value === '?' || el.value === '!') {
        sentenceEnd = index;
        sentences.push({
          text: sentenceText,
          startIndex: sentenceStart,
          endIndex: sentenceEnd,
          startTime: sentenceStartTime,
          endTime: sentenceEndTime,
          duration: sentenceDuration,
        });
        sentenceText = '';
        sentenceStart = index + 1;
        sentenceStartTime = el.end_ts;
      }
    });

    return sentences;
  }

  async disposeRenderer() {
    if (this.renderer) {
      console.log('dispose renderer');
      this.renderer.dispose();
      this.renderer = undefined;
    }
  }

  async applyChangesToFrameLockedTracks(
    prevState: RendererState,
    newState: RendererState,
  ) {
    const frameLockedTrackChanges = getOriginalVideoTrackSourceDiff(
      prevState.elements,
      newState.elements,
    );
    // console.log('frameLockedTrackChanges', frameLockedTrackChanges);
    if (Object.keys(frameLockedTrackChanges).length !== 1) {
      console.log(
        'No frame locked tracks changed or more than one, not applying',
      );
      return false;
    }
    const id = Object.keys(frameLockedTrackChanges)[0];
    const trackChanges = getElementPositionProperties(
      newState.elements.find((el) => el.source.id === id)!,
    ); // frameLockedTrackChanges[id];
    // console.log('trackChanges positional', trackChanges);
    // const track = trackChanges.track;
    const modificationObject: any = {};
    for (const element of newState.elements.filter(
      (el) => this.frameLockedTracks.includes(el.track) && el.source.id !== id,
    )) {
      for (const key in trackChanges) {
        modificationObject[`${element.source.id}.${key}`] =
          trackChanges[key] || null;
      }
      modificationObject[`${element.source.id}.clip`] = true;
    }
    if (Object.keys(modificationObject).length > 0) {
      await this.renderer?.applyModifications(modificationObject);
      return true;
    }
    return false;
  }

  async initializeVideoPlayer(
    htmlElement: HTMLDivElement,
    mode: 'interactive' | 'player' = 'interactive',
    origin: string = 'creator-studio',
  ) {
    if (this.renderer) {
      this.renderer.dispose();
      this.renderer = undefined;
    }

    const renderer = new Renderer(
      htmlElement,
      mode,
      'public-juuabbc8amz25dcfhribv3ss',
    );

    renderer.onReady = async () => {
      await renderer.setZoom('auto');
      await renderer.setLoop(false);
      await renderer.setCacheBypassRules([]);
      this.videoTranscriptionProcessor.setRenderer(renderer);
      this.karaokeProducer.setRenderer(renderer);
      if (this.currentVideo) {
        const videoSourceUrl = this.getVideoSourceUrlForResLevel(
          DEFAULT_PREVIEW_VIDEO_RES,
        );
        if (!this.cachedAssets.has(videoSourceUrl)) {
          await this.cacheVideoSource(videoSourceUrl);
          this.cachedAssets.add(videoSourceUrl);
        }
        await this.renderer!.setSource(this.currentVideo.videoSource);
      }
    };

    renderer.onLoad = async () => {
      runInAction(() => (this.isLoading = true));
    };

    renderer.onLoadComplete = async () => {
      runInAction(() => (this.isLoading = false));
    };

    renderer.onPlay = () => {
      runInAction(() => (this.isPlaying = true));
    };

    renderer.onPause = () => {
      runInAction(() => (this.isPlaying = false));
      runInAction(() => (this.whenPaused = new Date().getTime()));
    };

    renderer.onTimeChange = (time) => {
      if (
        origin === 'clip-player' &&
        this.whenPaused &&
        this.whenPaused - new Date().getTime() === 0
      ) {
        videoCreator.renderer?.play();
      }
      this.onRendererTimeChange(time);
    };

    renderer.onActiveElementsChange = (elementIds) => {
      runInAction(() => {
        this.activeElementIds = elementIds;
        videoCreator.textBrandingService.switchTemplateByType(elementIds);
        this.openElementSidebar(elementIds);
      });
    };

    renderer.onStateChange = async (state) => {
      if (!this.isPlaying && this.state && this.frameLockedTracks.length > 0) {
        const applied = await this.applyChangesToFrameLockedTracks(
          this.state,
          state,
        );
        // applying changes to other tracks will trigger state change again, skipping rest
        if (applied) return;
      }

      runInAction(() => {
        // populate missing field trimStart
        state.elements.forEach((element) => {
          element.trimStart = element.source.trim_start || 0;
        });
        this.state = state;
        this.duration = state.duration;
        this.stateReady = !!state.duration;
      });

      if (this.isPlaying) {
        return;
      }

      if (inDebugMode()) {
        // Group by elements[i].track
        const perTrackState = groupBy(
          state.elements,
          (element) => element.track,
        );
        console.log('STATE CHANGE', perTrackState);
      } else {
        // Preserving original behavior.
        console.log('Renderer state change', state);
      }

      const maxElementDuration = state?.elements.reduce(
        (acc, el) => Math.max(el.duration, acc),
        0,
      );

      runInAction(() => {
        this.tracks = groupBy(state.elements, (element) => element.track);

        if (
          DEBUG_TRANSCRIPTION_TIMELINE &&
          process.env.REACT_APP_API_URL?.startsWith('http://localhost')
        ) {
          this.tracks.set(
            KARAOKE_TRACK_NUMBER,
            test_injectTranscriptionElementsInTimeline(
              this.finalTranscriptionElements || [],
            ),
          );
        }
        this.maxCanvasScale = Math.min(
          this.maxTimelineScale,
          MAX_CANVAS_WIDTH / maxElementDuration, // waveform canvas cannot be wider than 32767px, will not render
        );
      });
      for (const element of state.elements) {
        if (
          element.source.type === 'audio' &&
          !this.audioTracksData[element.source.source]
        ) {
          this.loadWaveformForSource({
            source: element.source.source,
            name: element.source.name,
          });
        }
      }

      await this.lockVideoTrackOnStateChange(state.elements);
    };

    this.renderer = renderer;
    return true;
  }

  async refreshSourceAndPlay() {
    await this.renderer?.setSource(this.renderer?.getSource(), false);
    this.renderer?.play();
  }

  async loadWaveformForSource(source: { source: string; name: string }) {
    // console.log('loading waveform for source', source);
    this.audioTracksData[source.source] = 'loading';
    try {
      const { waveformJson, duration } =
        await fetchWaveformDataForAudioWithTitle(source.name);
      runInAction(() => {
        // console.log('waveform loaded', waveformJson);
        this.audioTracksData[source.source] = {
          waveform: WaveformData.create(waveformJson),
          originalTrackDuration: duration,
        };
      });
    } catch (err) {
      console.log('error loading waveform', err);
      delete this.audioTracksData[source.source];
    }
  }

  async onRendererTimeChange(time: number) {
    if (this.isScrubbing) return;
    const shouldApplyVolumeKeyPoints =
      this.isPlaying && Math.abs(time - this.keyPointsLastUpdateTime) > 1;
    if (time < this.time - 1 || Math.abs(time - this.smootherTime) > 1) {
      runInAction(() => (this.smootherTime = time));
    }
    runInAction(() => (this.time = time));
    if (!shouldApplyVolumeKeyPoints) return;
    // change the audio of clips dynamically based on VolumeKeyPoints
    this.state?.elements.forEach((element) => {
      const volumeKeyPoints = (
        this.currentVideo?.extraElementData[
          element.source.id
        ] as ExtraElementData | null
      )?.volumeKeyPoints;
      // console.log('Volume keypoints apply', volumeKeyPoints);
      // check if element is playing and has volumeKeyPoints
      if (
        element.localTime > time ||
        element.localTime + element.duration < time ||
        !volumeKeyPoints ||
        volumeKeyPoints.length < 2
      )
        return;

      // interpolate between two closest volumeKeyPoints to this time
      const relativeTime = time - element.localTime;
      const prevVolumeKeyPoint = volumeKeyPoints.reduce(
        (prev: any, curr: any) => {
          const currTime = parseFloat(curr.time);
          const prevTime = parseFloat(prev.time);
          return currTime < relativeTime &&
            relativeTime - currTime < relativeTime - prevTime
            ? curr
            : prev;
        },
        volumeKeyPoints[0],
      );
      const prevVolumeKeyPointIndex =
        volumeKeyPoints.indexOf(prevVolumeKeyPoint);
      const nextVolumeKeyPoint = volumeKeyPoints[prevVolumeKeyPointIndex + 1];
      const prevTime = parseFloat(prevVolumeKeyPoint.time);
      const nextTime = parseFloat(nextVolumeKeyPoint.time);
      const prevVolume = parseFloat(prevVolumeKeyPoint.value);
      const nextVolume = parseFloat(nextVolumeKeyPoint.value);
      const volume =
        nextTime - prevTime < 0.001
          ? prevVolume
          : prevVolume +
            ((nextVolume - prevVolume) * (relativeTime - prevTime)) /
              (nextTime - prevTime);

      if (volume >= 0 && volume < 100) {
        // console.log('changing volume');
        // todo does it create undo point?
        videoCreator.renderer?.setModifications({
          [`${element.source.id}.volume`]: volume,
        });
      }
    });
    runInAction(() => (this.keyPointsLastUpdateTime = time));
  }

  getMaxTrack(): number {
    return Math.max(
      ...(this.state?.elements.map((element) =>
        element.track < KARAOKE_TRACK_NUMBER ? element.track : 0,
      ) || []),
      1,
    );
  }

  async setTime(
    time: number,
    resetAnimations: boolean = false,
    forceSnap: boolean = false,
  ): Promise<void> {
    // make sure time is on a single frame
    time = this.snapTime(time, forceSnap);
    time = Math.ceil(time * 1000) / 1000;
    this.time = time;
    if (resetAnimations) {
      runInAction(() => (this.smootherTime = time));
    }
    await this.renderer?.setTime(time);
  }

  setShiftDown(shiftDown: boolean) {
    this.isShiftKeyDown = shiftDown;
  }

  snapTime(time: number, forceSnap: boolean = false): number {
    if (this.isShiftKeyDown || forceSnap) {
      return Math.round(time * 24) / 24;
    } else {
      return time;
    }
  }

  async setDuration(time: number | null): Promise<void> {
    if (time != null) {
      runInAction(() => (this.duration = time));
    }

    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }

    const source = renderer.getSource(renderer.state);

    source.duration = time;

    await renderer.setSource(source, true);
    this.createDefaultUndoPoint();
  }

  async applyVideoStateModifications(
    modifications: Record<string, string | number | null | undefined>,
    undo: boolean = false,
    actionLabel?: string,
    stackSameAction?: boolean,
  ) {
    if (undo && (!stackSameAction || this.getUndoLabel() === actionLabel)) {
      this.saveVideoStateSnapshot('undo', actionLabel);
    }
    await this.renderer!.applyModifications(modifications);
  }

  saveVideoStateSnapshot(into: 'undo' | 'redo' = 'undo', label?: string) {
    if (
      into === 'undo' &&
      this.currentVideo &&
      this.currentVideoVersions?.find((v) => v.meta.is_current)?.versionId ===
        this.currentVideo?.versionId &&
      Date.now() - +new Date(this.currentVideo!._publishedAt || 0) >
        AUTOSAVE_INTERVAL
    ) {
      this.saveStoryAndVideo(false, true, false, true);
    } else {
      this.prepareCurrentVideoForSave(true);
    }
    const videoSnapshot: CurrentLoadedVideo & { label?: string } = {
      ...toJS(this.currentVideo!),
      subtitles: this.subtitlesProcessor.getSubtitleLines(),
      label,
    };

    if (into === 'undo') {
      this.videoSnapshots.push(videoSnapshot);
      if (this.videoSnapshots.length > MAX_UNDO_REDO_STACK_SIZE) {
        this.videoSnapshots.shift();
      }
      this.redoSnapshots = [];
      this.redoStack = [];
      this.undoStack.push({
        undoCommand: async () => {
          this.isLoading = true;
          this.saveVideoStateSnapshot('redo', label);
          const videoSnapshot = this.videoSnapshots.pop()!;
          await this.renderVideo(
            videoSnapshot,
            false,
            DEFAULT_PREVIEW_VIDEO_RES,
          );
          this.textBrandingService.loadDefaultTemplateOnVideoLoad();
          this.isLoading = false;
        },
        redoCommand: async () => {
          this.isLoading = true;
          const videoSnapshotRedo = this.redoSnapshots.pop()!;
          // console.log('redo snapshots', toJS(videoSnapshot));
          this.videoSnapshots.push(videoSnapshot);
          await this.renderVideo(
            videoSnapshotRedo,
            false,
            DEFAULT_PREVIEW_VIDEO_RES,
          );
          this.textBrandingService.loadDefaultTemplateOnVideoLoad();
          this.isLoading = false;
        },
      });
    } else {
      this.redoSnapshots.push(videoSnapshot);
    }
  }

  createDefaultUndoPoint() {
    // this.undoStack.push({
    //   undoCommand: () => this.renderer?.undo(),
    //   redoCommand: () => this.renderer?.redo(),
    // });
  }

  createUndoPointForTranscription() {
    // this.undoStack.push({
    //   undoCommand: () => this.videoTranscriptionProcessor.undo(),
    //   redoCommand: () => this.videoTranscriptionProcessor.redo(),
    // });
  }

  openElementSidebar(elementIds: string[]) {
    if (elementIds.length !== 1) return;
    const elementId = elementIds[0];
    const element = this.renderer
      ?.getElements()
      ?.find((e) => e.source.id === elementId);

    if (!element) return;

    if (element.source.track === KARAOKE_TRACK_NUMBER) {
      this.sidebarOptions = SidebarOption.aiProducer;
      this.aiProducerSubMenu = AIProducerCard.karoke_text;
    } else if (element.source.type === 'text') {
      this.sidebarOptions = SidebarOption.text;
    } else {
      this.sidebarOptions = SidebarOption.editing;
    }
  }

  isVideoTrackLocked(elements = this.renderer?.getElements()) {
    const videoElements = elements?.filter((e) =>
      this.isOriginalVideoElement(e.source),
    );
    return videoElements?.every((e) => e.source.locked);
  }

  async lockVideoTrack(elements = this.renderer?.getElements()) {
    const videoElements = elements?.filter((e) =>
      this.isOriginalVideoElement(e.source),
    );
    const modifications = {} as Record<string, any>;

    for (let e of videoElements || []) {
      modifications[`${e.source.id}.locked`] = true;
    }
    if (Object.keys(modifications).length > 0) {
      await this.renderer?.applyModifications(modifications);
    }
  }

  async unLockVideoTrack(elements = this.renderer?.getElements()) {
    const videoElements = elements?.filter((e) =>
      this.isOriginalVideoElement(e.source),
    );
    if (videoElements?.every((e) => !e.source.locked)) return;

    const modifications = {} as Record<string, any>;

    for (let e of videoElements || []) {
      modifications[`${e.source.id}.locked`] = false;
    }

    if (Object.keys(modifications).length > 0) {
      await this.renderer?.applyModifications(modifications);
    }
  }

  async lockVideoTrackOnStateChange(elements: ElementState[]) {
    if (this.isVideoTrackLocked(elements)) return;
    const activeElement = this.getActiveElement();

    if (
      !activeElement ||
      (activeElement && !this.isOriginalVideoElement(activeElement.source))
    ) {
      await this.lockVideoTrack(elements);
    }
  }

  setActiveElement(element: ElementState, shiftDown: boolean) {
    const isPunchlistItem = this.punchListData?.some(
      (p) => element.source.id === p.id,
    );

    const imageElement = this.getImageElement(element);
    const elementSource = imageElement.source;

    if (isPunchlistItem && !elementSource.source) {
      videoCreator.openPhotoElementReplacementModal = {
        element: this.state!.elements.find(
          (el) => el.source.id === element.source.id,
        )!,
      };
      videoCreator.sidebarOptions = SidebarOption.aiProducer;
    } else {
      videoCreator.sidebarOptions = SidebarOption.editing;
    }

    if (shiftDown && this.activeElementIds.length > 0) {
      if (this.activeElementIds.includes(element.source.id)) {
        this.setActiveElements(
          ...this.activeElementIds.filter((id) => id !== element.source.id),
        );
        return;
      }

      const currentActiveElement = this.state?.elements.find(
        (el) => el.source.id === this.activeElementIds[0],
      );
      if (
        !currentActiveElement ||
        currentActiveElement.track !== element.track ||
        !this.isOriginalVideoElement(element.source)
      ) {
        this.setActiveElements(...this.activeElementIds, element.source.id);
        return;
      }

      let elementsInBetween = [];
      if (currentActiveElement.globalTime < element.globalTime) {
        elementsInBetween =
          this.state?.elements.filter(
            (el) =>
              el.globalTime + el.duration > currentActiveElement.globalTime &&
              el.globalTime < element.globalTime + element.duration,
          ) || [];
      } else {
        elementsInBetween =
          this.state?.elements.filter(
            (el) =>
              el.globalTime <
                currentActiveElement.globalTime +
                  currentActiveElement.duration &&
              el.globalTime + el.duration > element.globalTime,
          ) || [];
      }
      this.setActiveElements(
        ...new Set([
          ...this.activeElementIds,
          ...elementsInBetween.map((el) => el.source.id),
          element.source.id,
        ]),
      );
    } else if (shiftDown) {
      const elementsInBetween =
        this.state?.elements.filter(
          (el) =>
            el.globalTime + el.duration > element.globalTime &&
            el.globalTime < element.globalTime + element.duration,
        ) || [];
      this.setActiveElements(...elementsInBetween.map((el) => el.source.id));
    } else {
      this.setActiveElements(element.source.id);
    }

    if (
      element.source.track === KARAOKE_TRACK_NUMBER &&
      element.source.type === 'composition'
    ) {
      this.sidebarOptions = SidebarOption.aiProducer;
      this.aiProducerSubMenu = AIProducerCard.karoke_text;
    }

    if (element.source.type === 'text')
      videoCreator.sidebarOptions = SidebarOption.text;

    if (this.isOriginalVideoElement(element.source)) {
      this.unLockVideoTrack();
    } else {
      if (!this.isVideoTrackLocked()) this.lockVideoTrack();
    }
  }

  async setActiveElements(...elementIds: string[]): Promise<void> {
    this.activeElementIds = elementIds;
    this.selectedTrack = -1;
    await this.renderer?.setActiveElements(elementIds);
    await this.renderer?.setActiveComposition(null);
  }

  getActiveElement(): ElementState | undefined {
    if (!this.renderer || this.activeElementIds.length === 0) {
      return undefined;
    }

    const id = videoCreator.activeElementIds[0];
    return this.renderer.findElement(
      (element) => element.source.id === id,
      this.state,
    );
  }

  async createElement(
    elementSource: Record<string, any>,
    undo: boolean = true,
  ): Promise<void> {
    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }
    if (undo) {
      this.saveVideoStateSnapshot(
        undefined,
        `creating ${elementSource.type} element`,
      );
    }
    const source = renderer.getSource(renderer.state);
    // check if top track is free
    const currTime = this.time;
    const maxTrack = this.getMaxTrack();
    const id = uuid();

    source.elements.push({
      id,
      track: maxTrack + 1,
      time: currTime,
      ...elementSource,
    });

    await renderer.setSource(source, undo);
    if (undo) {
      this.createDefaultUndoPoint();
    }
    await this.setActiveElements(id);
  }

  async deleteElementWithTranscription(elementId: string): Promise<void> {
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToDelete = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToDelete) {
      return;
    }

    this.saveVideoStateSnapshot(
      undefined,
      `deleting ${elementToDelete.source.type} element`,
    );

    const fadeProducer = new FadeProducer(elementToDelete);

    if (this.isOriginalVideoElement(elementToDelete.source)) {
      await this.videoTranscriptionProcessor.deleteOriginalVideoTrack(
        elementId,
      );
      await fadeProducer.tidyOriginalVideoOverlay();
      this.refreshKaraokeElements();
      this.createUndoPointForTranscription();
    } else {
      // if (elementToDelete?.source.type === 'audio') {
      //   state.elements.forEach((element) => {
      //     if (
      //       element.source.type === 'audio' &&
      //       element.globalTime >=
      //       elementToDelete.globalTime + elementToDelete.duration
      //     ) {
      //       element.source.time = element.globalTime - elementToDelete.duration;
      //     }
      //   });
      // }

      // Remove the element
      state.elements = state.elements.filter(
        (element) => element.source.id !== elementId,
      );

      // Set source by the mutated state
      const newSource =
        this.videoTranscriptionProcessor.adjustTrackNumbersToStartFromOne(
          this.renderer!.getSource(state),
        );
      await this.renderer!.setSource(newSource, true);
      this.updateFrameLockedTracksAfterRearrange(
        state.elements,
        this.renderer!.state!.elements,
      );

      await fadeProducer.removeElementOverlayOnVideo(elementId);
      if (this.hasPhotoHighlight(elementId)) {
        this.removeAPhotoHighlightTranscriptChange(elementId);
        this.punchListData = this.punchListData!.filter(
          (p) => p.id !== elementId,
        );
      }
    }
  }

  async deleteKaraokeElements() {
    this.saveVideoStateSnapshot(undefined, `deleting karaoke`);
    await this.karaokeProducer.deleteKaraokeElements();
    this.removeAllKaraokeBreaks();
  }

  async addPunctuation(
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
    position: number,
    isSubtitles = false,
  ) {
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.addPunctuation(code, position, {
        ...(code === 'Enter' &&
          this.karaokeProducer.hasElements() &&
          !this.hasKaraokeBreakNear(position) && { karaoke_break: true }),
      });
    } else {
      this.subtitlesProcessor.addPunctuation(code, position);
    }
    this.refreshKaraokeElements();
  }

  hasKaraokeBreakNear(position: number, isSubtitles = false): boolean {
    const startIndex = Math.max(
      getClosestNotRemovedTextIndexToLeft(
        position - 1,
        this.finalTranscriptionElements!,
      ),
      0,
    );
    let endIndex = getClosestNotRemovedTextIndexToRight(
      position + 1,
      this.finalTranscriptionElements!,
    );
    if (endIndex === -1) {
      endIndex = this.finalTranscriptionElements!.length;
    }
    for (let i = startIndex; i < endIndex; i++) {
      const element = this.finalTranscriptionElements![i];
      if (
        element.state !== 'removed' &&
        element.state !== 'cut' &&
        element.karaoke_break
      ) {
        return true;
      }
    }
    return false;
  }

  addKaraokeBreaks(positions: number[], isSubtitles = false) {
    console.trace('add karaoke breaks', positions, isSubtitles);
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.addKaraokeBreaks(positions);
    } else {
      this.subtitlesProcessor.addKaraokeBreaks(positions);
    }
    this.refreshKaraokeElements();
  }

  removeKaraokeBreak(position: number, isSubtitles = false) {
    debugger;
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.removeKaraokeBreak(position);
    } else {
      this.subtitlesProcessor.removeKaraokeBreak(position);
    }
    this.refreshKaraokeElements();
  }

  removeAllKaraokeBreaks(isSubtitles = false) {
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.removeAllKaraokeBreaks();
    } else {
      this.subtitlesProcessor.removeAllKaraokeBreaks();
    }
    this.refreshKaraokeElements();
  }

  isSelectedVolumeKeyPoint(point: VolumeKeyPoint): boolean {
    return (
      point.time === this.selectedVolumeKeyPoint?.time &&
      point.value === this.selectedVolumeKeyPoint?.value
    );
  }

  selectVolumeKeyPoint(point: VolumeKeyPoint | null): void {
    this.selectedVolumeKeyPoint = point;
  }

  deleteSelectedVolumeKeyPoint(elementId: string): void {
    if (!this.selectedVolumeKeyPoint) {
      return;
    }
    const oldVolumeKeyPoints =
      toJS(
        (
          this.currentVideo!.extraElementData[
            elementId
          ] as ExtraElementData | null
        )?.volumeKeyPoints,
      ) || [];
    let newVolumeKeyPoints = oldVolumeKeyPoints.filter(
      (p) => !this.isSelectedVolumeKeyPoint(p),
    );
    if (newVolumeKeyPoints.length === 2) {
      newVolumeKeyPoints = [];
    }
    this.applyVolumeKeyPoints(elementId, newVolumeKeyPoints);
    this.selectedVolumeKeyPoint = null;
  }

  deleteAllVolumeKeyPoints(elementId: string): void {
    this.applyVolumeKeyPoints(elementId, []);
    this.selectedVolumeKeyPoint = null;
  }

  private extendRangeToNecessaryWhitespaces(
    startIndex: number,
    endIndex: number,
    elements: TranscriptElement[],
  ): [number, number][] {
    if (!elements) {
      return [[startIndex, endIndex]];
    }
    endIndex--;
    /*
     * look for the prev visible element
     * if found and it's not a whitespace
     *   -> include preceding whitespace into restore
     */
    const prevVisibleElementIndex = getClosestNotRemovedElementIndexToLeft(
      startIndex - 1,
      elements,
    );
    if (
      prevVisibleElementIndex >= 0 &&
      elements[prevVisibleElementIndex].value !== ' ' &&
      elements[startIndex - 1]?.value === ' '
    ) {
      startIndex--;
    }
    /*
     * look for the next visible element
     * if found and it's not a punctuation
     *   -> include following whitespace into restore
     *   if restored range is not immediately followed by a whitespace
     *     -> find whitespace before next visible element and create a new range with it alone
     */
    const nextVisibleElementIndex = getClosestNotRemovedElementIndexToRight(
      endIndex + 1,
      elements,
    );
    if (
      nextVisibleElementIndex < elements.length &&
      nextVisibleElementIndex > -1 &&
      elements[nextVisibleElementIndex].type === 'text'
    ) {
      if (elements[endIndex + 1].value === ' ') {
        return [[startIndex, endIndex + 2]];
      } else {
        for (let i = endIndex + 2; i < nextVisibleElementIndex; i++) {
          if (elements[i]?.value === ' ') {
            return [
              [startIndex, endIndex + 1],
              [i, i + 1],
            ];
          }
        }
      }
    }
    return [[startIndex, endIndex + 1]];
  }

  excludeMutedByHideFillers = (
    ranges: [number, number][],
    elements: TranscriptElement[],
  ): [number, number][] => {
    const result: [number, number][] = [];
    for (const [startIndex, endIndex] of ranges) {
      for (let i = startIndex, rangeStart = startIndex; i <= endIndex; i++) {
        if (elements[i].muted_by_hideFillers) {
          if (i === 0) {
            rangeStart++;
          } else if (i === endIndex) {
            result.push([rangeStart, endIndex - 1]);
          } else {
            result.push([rangeStart, i - 1]);
            rangeStart = i + 1;
          }
        } else if (i === endIndex) {
          result.push([rangeStart, endIndex]);
        }
      }
    }
    return result;
  };

  async restoreTranscriptAndVideo(
    startIndex: number,
    endIndex: number,
    isSubtitles: boolean = false,
    shouldExcludeMutedByHideFillers: boolean = false,
  ) {
    const elements = isSubtitles
      ? this.subtitleElements
      : this.finalTranscriptionElements;
    if (!elements) {
      console.error('No elements to restore');
      return;
    }
    this.saveVideoStateSnapshot(
      undefined,
      `restoring ${isSubtitles ? 'subtitles' : 'text'}`,
    );

    let ranges = this.extendRangeToNecessaryWhitespaces(
      startIndex,
      endIndex,
      elements,
    );

    if (shouldExcludeMutedByHideFillers) {
      ranges = this.excludeMutedByHideFillers(ranges, elements);
    }

    for (const [fromElement, toElement] of ranges) {
      let fromIndex = fromElement;
      // loop through all removed elements in the range
      while (fromIndex >= 0 && fromIndex <= toElement) {
        const nextIndex = getClosestNotRemovedTextIndexToRight(
          fromIndex,
          elements,
          false,
        );
        const nextRemovedElement =
          nextIndex > -1
            ? getClosestRemovedIndexToLeft(nextIndex, elements)
            : elements.length - 1;
        if (nextRemovedElement === -1) break;

        if (isSubtitles) {
          this.subtitlesProcessor.restoreMutedTextElements(
            fromIndex,
            Math.min(nextRemovedElement + 1, toElement),
          );
        } else if (elements[fromIndex].state === 'muted') {
          await this.videoTranscriptionProcessor.restoreMutedTextElement(
            fromElement,
            Math.min(nextRemovedElement + 1, toElement),
          );
        } else {
          await this.videoTranscriptionProcessor.restoreTextElementsFromOriginal(
            fromIndex,
            Math.min(nextRemovedElement + 1, toElement),
          );
        }
        this.createUndoPointForTranscription();
        fromIndex = getClosestRemovedIndexToRight(
          nextRemovedElement + 1,
          elements,
        );
      }
    }

    const fadeProducer = new FadeProducer();
    await fadeProducer.tidyOriginalVideoOverlay();
    if (ranges.length > 0 && !isSubtitles) {
      this.refreshKaraokeElements({
        fromIndex: ranges[0][0],
        toIndex: ranges.at(-1)![1],
      });
    } else {
      this.refreshKaraokeElements();
    }
  }

  replaceTranscriptionElement(
    startIndex: number,
    endIndex: number,
    newValue: string,
    isSubtitles = false,
  ) {
    this.saveVideoStateSnapshot(
      undefined,
      `replacing ${isSubtitles ? 'subtitles' : 'text'}`,
    );
    if (isSubtitles) {
      this.subtitlesProcessor.replaceSubtitlesElement(
        startIndex,
        endIndex,
        newValue,
      );
    } else {
      this.videoTranscriptionProcessor.replaceTextElement(
        startIndex,
        endIndex,
        newValue,
      );
    }
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  hideKaraoke(
    boundaries: { startIndex: number; endIndex: number },
    isSubtitles = false,
  ) {
    this.saveVideoStateSnapshot(undefined, `hiding karaoke`);
    if (isSubtitles) {
      this.subtitlesProcessor.hideKaraoke(boundaries);
    } else {
      this.videoTranscriptionProcessor.hideKaraoke(boundaries);
    }
    this.refreshKaraokeElements();
  }

  async refetchSubtitlesForCurrentVideo() {
    if (this.currentVideo!.subtitles) {
      await this.requestSubtitlesForCurrentVideo();
    }
    this.refreshKaraokeElements();
  }

  getVideoSubtitleElements(): TranscriptElement[] | undefined {
    if (!this.currentVideo?.subtitles?.lines?.length) {
      return;
    }
    return this.subtitlesProcessor.convertToSubtitleElements(
      this.currentVideo?.subtitles,
    );
  }

  async refreshKaraokeElements(addBreaksForRange?: {
    fromIndex: number;
    toIndex: number;
  }) {
    if (this.karaokeProducer.hasElements()) {
      this.karaokeProducer.produceKaraoke(undefined, addBreaksForRange);
    }
  }

  async cutTranscriptAndVideo(
    startIndex: number,
    endIndex: number,
    autoCorrect: boolean = false,
    isSubtitles = false,
  ) {
    this.saveVideoStateSnapshot(
      undefined,
      `removing ${isSubtitles ? 'subtitles' : 'text'}`,
    );
    if (isSubtitles) {
      this.subtitlesProcessor.removeSubtitleElements(startIndex, endIndex);
    } else {
      await this.videoTranscriptionProcessor.removeTextElements(
        startIndex,
        endIndex,
        false,
        autoCorrect,
      );
      const fadeProducer = new FadeProducer();
      await fadeProducer.tidyOriginalVideoOverlay();
    }
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async cropTranscriptAndVideoTo(
    startIndex: number,
    endIndex: number,
    originalDuration: number,
  ) {
    // this makes clips save twice and have incorrect clipJson
    // this.saveVideoStateSnapshot(undefined, `cropping clip`);
    await this.videoTranscriptionProcessor.cropVideoToKeepTextElements(
      startIndex,
      endIndex,
    );
    const fadeProducer = new FadeProducer();
    await fadeProducer.tidyOriginalVideoOverlay();
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();

    // zoom timeline to new video duration
    const ratio = originalDuration / this.duration;
    runInAction(() => {
      this.defaultTimelineScale = this.defaultTimelineScale * ratio;
      this.timelineScale = this.timelineScale * ratio;
    });
  }

  async cutSentence(
    startIndex: number,
    endIndex: number,
    autoCorrect: boolean = false,
  ) {
    this.saveVideoStateSnapshot(undefined, 'cutting text');
    await this.videoTranscriptionProcessor.removeTextElements(
      startIndex,
      endIndex + 1,
      true,
      autoCorrect,
    );
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async pasteSentence(intoPosition: number) {
    const addedRange =
      await this.videoTranscriptionProcessor.pasteFromClipboard(intoPosition);

    const fadeProducer = new FadeProducer();
    await fadeProducer.tidyOriginalVideoOverlay();

    this.refreshKaraokeElements(addedRange);
    this.createUndoPointForTranscription();
  }

  async moveSentence(
    startIndex: number,
    endIndex: number,
    newStartIndex: number,
  ) {
    this.saveVideoStateSnapshot(undefined, `moving text`);
    await this.videoTranscriptionProcessor.moveTextElements(
      startIndex,
      endIndex,
      newStartIndex,
    );
    this.createUndoPointForTranscription();
  }

  async applyVolumeKeyPoints(
    elementId: string,
    volumeKeyPoints: VolumeKeyPoint[],
    undo: boolean = true,
  ) {
    const oldVolumeKeyPoints = toJS(
      (
        this.currentVideo!.extraElementData[
          elementId
        ] as ExtraElementData | null
      )?.volumeKeyPoints,
    );
    if (
      volumeKeyPoints.length === oldVolumeKeyPoints?.length &&
      volumeKeyPoints.every(
        (kp, idx) =>
          kp.time === oldVolumeKeyPoints[idx].time &&
          kp.value === oldVolumeKeyPoints[idx].value,
      )
    ) {
      return;
    }

    if (undo) {
      this.saveVideoStateSnapshot('undo', 'modifying volume keyframes');
    }

    this.currentVideo!.extraElementData[elementId] = {
      ...(this.currentVideo!.extraElementData[elementId] || {}),
      volumeKeyPoints,
    };
    // todo undo/redo
    // this.undoStack.push({
    //   undoCommand: () => {
    //     this.currentVideo!.extraElementData[elementId].volumeKeyPoints =
    //       oldVolumeKeyPoints;
    //   },
    //   redoCommand: () => {
    //     this.currentVideo!.extraElementData[elementId].volumeKeyPoints =
    //       volumeKeyPoints;
    //   },
    // });
  }

  async adjustTextDimensions(aspectRatio: Video['aspectRatio']) {
    const elements = this.renderer?.getElements() || [];

    let modifications = {} as Record<string, any>;
    const elementsInComposition = {} as any;
    const defaultTextSetting =
      this.textProducer.getBasicTextSettingByAspectRatio(aspectRatio);
    const defaultKaraokeSetting =
      this.karaokeProducer.getKaraokeTextSettingByAspectRatio(aspectRatio);

    let width = this.renderer?.getSource()?.width || 1280;
    let height = this.renderer?.getSource()?.height || 720;

    for (let el of elements) {
      if (el.source.type === 'composition') {
        el.elements?.forEach((e: any) => {
          elementsInComposition[e.source.id] = e;
        });
      }
    }
    for (let el of elements) {
      if (
        el.source.type === 'text' &&
        !elementsInComposition[el.source.id] &&
        el.track !== KARAOKE_TRACK_NUMBER
      ) {
        for (let [key, value] of Object.entries(defaultTextSetting)) {
          if (key === 'font_size') {
            const vhValue = convertFromPixels(parseInt(value), 'vh', {
              height,
              width,
            });
            value = `${vhValue}vh`;
            modifications[`${el.source.id}.${key}`] = value;
          }
        }
      }

      if (el.source.type === 'text' && el.track === KARAOKE_TRACK_NUMBER) {
        for (let [key, value] of Object.entries(defaultKaraokeSetting)) {
          if (key === 'font_size') {
            const vhValue = convertFromPixels(parseInt(value), 'vh', {
              height,
              width,
            });
            value = `${vhValue}vh`;
            modifications[`${el.source.id}.${key}`] = value;
          }
        }
        videoCreator.karaokeProducer.setConfig({
          ...videoCreator.karaokeProducer.getKaraokeConfig(),
          ...defaultKaraokeSetting,
        });
      }
    }

    if (Object.keys(modifications).length) {
      await videoCreator.renderer?.applyModifications({
        ...modifications,
      });
    }
  }

  async adjustResetElementOnAspectRatioChange() {
    const elements = this.renderer?.getElements() || [];
    const modifications: Record<string, any> = {};
    const defaults = {
      x: '50%',
      y: '50%',
      width: '100%',
      height: '100%',
    } as Record<string, string>;

    function hasDifferentValues(obj: Record<string, any>) {
      return Object.keys(defaults).some(
        (key) => obj[key] !== undefined && obj[key] !== defaults[key],
      );
    }

    for (let el of elements) {
      const isBroll =
        el.source.type === 'video' && !this.isOriginalVideoElement(el.source);
      const isRegularImage = this.isImageElement(el) && !this.isLogoElement(el);

      if (isBroll || isRegularImage) {
        if (hasDifferentValues(el.source)) {
          modifications[`${el.source.id}.x`] = defaults.x;
          modifications[`${el.source.id}.y`] = defaults.y;
          modifications[`${el.source.id}.width`] = defaults.width;
          modifications[`${el.source.id}.height`] = defaults.height;
        }
      }
    }

    if (Object.keys(modifications).length) {
      await videoCreator.renderer?.applyModifications({
        ...modifications,
      });
    }
  }

  getImageElement(element: ElementState) {
    if (element.source?.type === 'composition') {
      const image_element = element?.elements?.find(
        (e) => e.source?.type === 'image',
      );
      if (image_element) return image_element;
    }
    return element;
  }

  isImageElementComposition(element: ElementState) {
    if (element.source?.type === 'composition') {
      return element?.elements?.some((e) => e.source?.type === 'image');
    }
    return false;
  }

  async removeBlackFrames() {
    const compositionElements = this.renderer
      ?.getElements()
      ?.filter((e) => this.isImageElementComposition(e));
    let modifications = {} as Record<string, any>;

    for (let el of compositionElements || []) {
      const imageElement = (el.elements || [])?.find(
        (e) => e.source.type === 'image',
      );
      const elementSource = {
        ...el.source,
      };
      elementSource.source = imageElement!.source.source;
      elementSource.type = 'image';
      elementSource.fit = 'cover';

      elementSource.locked = false;
      delete elementSource.id;

      for (let key in elementSource) {
        modifications[`${el.source.id}.${key}`] = elementSource[key];
      }
    }

    if (Object.keys(modifications).length) {
      await videoCreator.renderer?.applyModifications({
        ...modifications,
      });
    }
  }

  isLogoElement(element: ElementState): boolean {
    return (
      this.isImageElement(element) &&
      !!(
        this.currentVideo!.extraElementData[
          `logo_el_${element.source.id}`
        ] as ExtraElementData
      )?.isLogo
    );
  }

  isImageElement(element: ElementState) {
    return (
      this.isImageElementComposition(element) ||
      element.source?.type === 'image'
    );
  }

  imageCompositionDurationModifications(
    element: ElementState,
    duration: number,
  ) {
    const additionalModifications: Record<string, any> = {};

    if (this.isImageElementComposition(element)) {
      for (let e of element.elements || []) {
        additionalModifications[`${e.source.id}.duration`] = duration;
      }
    }
    return additionalModifications;
  }

  async moveElements(elementIds: string[], timeShift: number) {
    this.saveVideoStateSnapshot(undefined, `moving elements`);
    await this.videoTranscriptionProcessor.moveElements(elementIds, timeShift);
    // await fadeProducer.tidyOriginalVideoOverlay(); todo for multiple elements?
    this.createUndoPointForTranscription();
    this.refreshKaraokeElements();
    return;
  }

  async applyPlacement(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
  ) {
    if (
      element.globalTime === placement.globalTime &&
      element.duration === placement.duration
    )
      return;

    this.saveVideoStateSnapshot(
      undefined,
      `moving ${element.source.type} element`,
    );
    const fadeProducer = new FadeProducer(element);

    if (
      this.isOriginalVideoElement(element.source) &&
      this.finalTranscriptionElements
    ) {
      let addBreaksForRange;
      if ((element.source.trim_start || 0) !== (placement.trimStart || 0)) {
        addBreaksForRange =
          await this.videoTranscriptionProcessor.trimTrackStart(
            element.source.id,
            placement.globalTime,
            placement.trimStart,
            placement.duration,
          );
      } else if (element.globalTime !== placement.globalTime) {
        await this.videoTranscriptionProcessor.moveTrack(
          element.source.id,
          placement.globalTime,
        );
      } else if (element.duration !== placement.duration) {
        addBreaksForRange =
          await this.videoTranscriptionProcessor.trimTrackDuration(
            element.source.id,
            placement.duration,
          );
      }

      await fadeProducer.tidyOriginalVideoOverlay();
      this.createUndoPointForTranscription();
      this.refreshKaraokeElements(addBreaksForRange);
      return;
    }

    if (element.source.type === 'audio') {
      await this.applyPlacementOnAudio(element, placement);
      return;
    }

    if (element.track === KARAOKE_TRACK_NUMBER) {
      const originalLanguage = this.originalTranscription?.language;
      const karaokeConfig = this.karaokeProducer.getKaraokeConfig();

      await this.videoTranscriptionProcessor.applyKaraokeElementPlacement(
        element,
        placement,
        !originalLanguage?.includes('en') &&
          karaokeConfig.language.includes('en'),
      );
      this.refreshKaraokeElements();
      return;
    }

    const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
      placement.globalTime,
      placement.duration,
    );

    // for non-video elements and non-karaoke elements
    await this.renderer?.applyModifications({
      ...newVideoOverlays,
      [`${element.source.id}.time`]: placement.globalTime,
      [`${element.source.id}.duration`]: placement.duration,
      ...this.imageCompositionDurationModifications(
        element,
        placement.duration,
      ),
    });
    this.createDefaultUndoPoint();
  }

  private async applyPlacementOnAudio(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
  ) {
    const trimStart = placement.trimStart || 0;
    const time = Math.max(placement.globalTime, 0);
    if ((element.source.trim_start || 0) !== trimStart) {
      //handle trim audio start
      await this.trimAudioTrackStart(
        element,
        time,
        trimStart,
        placement.duration,
      );
    } else if (element.globalTime !== time) {
      //handle move track
      await this.renderer?.applyModifications({
        [`${element.source.id}.time`]: time,
        [`${element.source.id}.duration`]: element.source.duration,
      });
    } else if (element.duration !== placement.duration) {
      // handle trim audio end
      await this.renderer?.applyModifications({
        [`${element.source.id}.time`]: time,
        [`${element.source.id}.duration`]: placement.duration,
      });
    }
    // const volumeKeyPoints = (
    //   this.currentVideo!.extraElementData[
    //     element.source.id
    //   ] as ExtraElementData | null
    // )?.volumeKeyPoints;

    // if (volumeKeyPoints && volumeKeyPoints.length > 0) {
    //   runInAction(() => {
    //     this.applyVolumeKeyPoints(element.source.id, volumeKeyPoints, false);
    //   });
    // }
    this.createDefaultUndoPoint();
  }

  private async trimAudioTrackStart(
    element: ElementState,
    newStartTime: number,
    trimStart: number,
    newDuration: number,
  ) {
    const elementId = element.source.id;

    const stockSongs = this.stockMusic.flatMap((m) => m.collection) || [];
    const ownSongs = this.story?.myAudios?.map((o) => o.song) || [];
    const allSongs = [...stockSongs, ...ownSongs];

    let originalAudio = allSongs.find((c) => c.url === element.source.source);

    let mediaDuration = element.mediaDuration!;
    let originalMediaDuration = parseFloat(originalAudio?.customData.duration!);

    const currentTrimStart = element.source.trim_start || 0;

    if (currentTrimStart < trimStart) {
      mediaDuration = Math.min(
        Math.max(mediaDuration - trimStart, 0),
        originalMediaDuration,
      );
    } else {
      //untrim
      const untrimedTime = currentTrimStart - trimStart;
      mediaDuration = Math.min(
        mediaDuration + untrimedTime,
        originalMediaDuration,
      );
    }

    // Trim the element
    await this.renderer?.applyModifications({
      [`${elementId}.time`]: newStartTime,
      [`${elementId}.trim_start`]: trimStart,
      [`${elementId}.duration`]: newDuration,
      [`${elementId}.media_duration`]: mediaDuration,
    });
  }

  async rearrangeTracks(
    track: number,
    direction: 'up' | 'down',
  ): Promise<void> {
    if (track === KARAOKE_TRACK_NUMBER) return;

    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }

    // The track number to swap with
    const targetTrack = direction === 'up' ? track + 1 : track - 1;
    if (targetTrack < 1) {
      return;
    }

    // Elements at provided track
    const elementsCurrentTrack = renderer.state.elements.filter(
      (element) => element.track === track,
    );
    if (elementsCurrentTrack.length === 0) {
      return;
    }

    this.saveVideoStateSnapshot(undefined, `rearranging tracks`);
    // Clone the current renderer state
    const state = deepClone(renderer.state);

    // Swap track numbers
    for (const element of state.elements) {
      if (element.track === track) {
        element.source.track = targetTrack;
      } else if (element.track === targetTrack) {
        element.source.track = track;
      }
    }

    // Set source by the mutated state
    const newSource =
      this.videoTranscriptionProcessor.adjustTrackNumbersToStartFromOne(
        renderer.getSource(state),
      );
    await renderer.setSource(newSource, true);
    this.updateFrameLockedTracksAfterRearrange(
      state.elements,
      renderer.state.elements,
    );
    // adjust frame locked tracks
    this.createDefaultUndoPoint();
  }

  updateFrameLockedTracksAfterRearrange(
    oldStateElements: ElementState[],
    newStateElements: ElementState[],
  ) {
    this.frameLockedTracks = this.frameLockedTracks
      .map((track) => {
        const elementsInOldSource = oldStateElements.filter(
          (el) => el.track === track,
        );
        if (elementsInOldSource.length > 0) {
          const elementInNewSource = newStateElements.find((el) =>
            elementsInOldSource.find(
              (oldEl) => oldEl.source.id === el.source.id,
            ),
          );
          return elementInNewSource?.track;
        }
      })
      .filter((track) => track !== undefined) as number[];
  }

  removeAllPhotoHighlightTranscriptChanges() {
    this.videoTranscriptionProcessor.removeAllPhotoHighlights();
    this.createUndoPointForTranscription();
  }

  async removeAllAutoPhotoHighlightTranscriptChanges() {
    this.videoTranscriptionProcessor.removeAllAutoPhotoHighlights();
    this.createUndoPointForTranscription();
  }

  async resetPhotoHighlights() {
    this.removeAllPhotoHighlightTranscriptChanges();

    const timelineElements = this.renderer?.getElements();
    for (let item of this.punchListData || []) {
      const id = item.id!;
      const element = timelineElements?.find((el: any) => el.source.id === id);

      if (element) {
        const duration = element?.duration;
        const startTime = element.localTime;
        const endTime = startTime + duration;

        const startIndex =
          this.videoTranscriptionProcessor.findClosestTimestamp(
            startTime,
            'ts',
          );
        const endIndex = this.videoTranscriptionProcessor.findClosestTimestamp(
          endTime,
          'end_ts',
        );
        videoCreator.videoTranscriptionProcessor.addPhotoHighlight(
          startIndex,
          endIndex,
          id,
        );
      }
    }
    this.createUndoPointForTranscription();
  }

  hasPhotoHighlight(id: string) {
    return this.videoTranscriptionProcessor
      .getTranscriptionChanges()
      .some(
        (change) =>
          change?.type === 'photo_highlight' &&
          change?.newPhotoHighlightId === id,
      );
  }

  removeAPhotoHighlightTranscriptChange(id: string) {
    this.videoTranscriptionProcessor.removeSinglePhotoHighlight(id);
    this.createUndoPointForTranscription();
  }

  handleResetPhotoHighlight(
    element: ElementState,
    start: string | null = null,
    length: string | null = null,
  ) {
    const elementId = element.source.id;
    const hasHighlight = this.hasPhotoHighlight(elementId);
    if (!hasHighlight) return;
    this.removeAPhotoHighlightTranscriptChange(elementId);

    const time = start !== null ? start : element.source.time;
    const duration = length || element.duration;

    let startIndex;
    let endIndex;
    if (time !== null) {
      startIndex = this.videoTranscriptionProcessor.findClosestTimestamp(
        parseFloat(time),
      );
    }

    if (duration) {
      const endTime = parseFloat(time) + parseFloat(duration.toString());
      endIndex = this.videoTranscriptionProcessor.findClosestTimestamp(
        endTime,
        'end_ts',
      );
    }

    if (startIndex !== undefined && endIndex !== undefined) {
      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        elementId,
      );
    }
  }

  //todo move to VideoProcessor
  async cutCurrentTrack() {
    const renderer = this.renderer!;
    const source = renderer.getSource(renderer.state);

    const activeElement = this.getActiveElement()!;
    const elementIndex = source.elements.findIndex(
      (el: any) => el.id === activeElement.source.id,
    );
    const elementSource = source.elements[elementIndex];

    if (
      activeElement.globalTime > this.time ||
      activeElement.globalTime + activeElement.duration < this.time
    )
      return;

    this.saveVideoStateSnapshot(undefined, `cutting track`);
    const hasHighlight = this.hasPhotoHighlight(elementSource.id);

    // create head and tail elements
    const tailElementSource = {
      ...elementSource,
      id: uuid(),
      audio_fade_in: 0,
      animations: elementSource.animations?.filter(
        (a: any) => a.time === 'end',
      ),
    };
    const headElementSource = {
      ...elementSource,
      id: uuid(),
      audio_fade_out: 0,
      animations: elementSource.animations?.filter(
        (a: any) => a.time === 0 || a.time === 'start',
      ),
    };
    headElementSource.duration = this.time - (activeElement.globalTime || 0);
    tailElementSource.time = this.time;
    tailElementSource.duration =
      activeElement.duration - headElementSource.duration;
    tailElementSource.trim_start =
      (parseFloat(elementSource.trim_start || '0') || 0) +
      this.time -
      (activeElement.globalTime || 0);

    // get original volumeKeyPoints
    const volumeKeyPoints = (
      this.currentVideo!.extraElementData[
        elementSource.id
      ] as ExtraElementData | null
    )?.volumeKeyPoints;

    const fadeProducer = new FadeProducer(activeElement);
    fadeProducer.processCrossfadeOnElementCut(
      headElementSource,
      tailElementSource,
      source.elements,
    );

    source.elements.splice(
      elementIndex,
      1,
      headElementSource,
      tailElementSource,
    );

    // split volumeKeyPoints if exist
    if (volumeKeyPoints) {
      const headVolumeKeyPoints = volumeKeyPoints.filter(
        (kp) => parseFloat(kp.time) < headElementSource.duration,
      );
      const tailVolumeKeyPoints = volumeKeyPoints.filter(
        (kp) => parseFloat(kp.time) >= headElementSource.duration,
      );

      // figure out the volume at the cut point and add it to the head and the tail at the cut point
      // by interpolating the volume between the two closest volumeKeyPoints to the cut point
      const relativeTime = this.time - activeElement.globalTime;
      const prevVolumeKeyPoint =
        headVolumeKeyPoints[headVolumeKeyPoints.length - 1];
      const nextVolumeKeyPoint = tailVolumeKeyPoints[0];
      const prevTime = parseFloat(prevVolumeKeyPoint.time);
      const nextTime = parseFloat(nextVolumeKeyPoint.time);
      const prevVolume = parseFloat(prevVolumeKeyPoint.value);
      const nextVolume = parseFloat(nextVolumeKeyPoint.value);
      const volume =
        prevVolume +
        ((nextVolume - prevVolume) * (relativeTime - prevTime)) /
          (nextTime - prevTime);
      headVolumeKeyPoints.push({
        time: headElementSource.duration,
        value: `${volume} %`,
      });
      tailVolumeKeyPoints.unshift({ time: `${0} s`, value: `${volume} %` });

      this.currentVideo!.extraElementData[headElementSource.id] = {
        ...(this.currentVideo!.extraElementData[headElementSource.id] || {}),
        volumeKeyPoints: headVolumeKeyPoints,
      };
      this.currentVideo!.extraElementData[tailElementSource.id] = {
        ...(this.currentVideo!.extraElementData[tailElementSource.id] || {}),
        volumeKeyPoints: tailVolumeKeyPoints,
      };
    }

    await this.renderer!.setSource(source, true);
    await fadeProducer.processCrossfadeOnOriginalVideoCut();

    if (hasHighlight) {
      const headStartIndex =
        this.videoTranscriptionProcessor.findClosestTimestamp(
          headElementSource.time,
        );
      const headEndIndex =
        this.videoTranscriptionProcessor.findClosestTimestamp(
          headElementSource.time + headElementSource.duration,
          'end_ts',
        );

      const tailStartIndex =
        this.videoTranscriptionProcessor.findClosestTimestamp(
          tailElementSource.time,
        );
      const tailEndIndex =
        this.videoTranscriptionProcessor.findClosestTimestamp(
          tailElementSource.time + tailElementSource.duration,
          'end_ts',
        );

      this.addCutPhotoToPunchlist(
        elementSource.id,
        {
          id: headElementSource.id,
          transcriptPosition: {
            startIndex: headStartIndex,
            endIndex: headEndIndex,
          },
        },
        {
          id: tailElementSource.id,
          transcriptPosition: {
            startIndex: tailStartIndex,
            endIndex: tailEndIndex,
          },
        },
      );
      this.resetPhotoHighlights();
    }

    this.createDefaultUndoPoint();
    await this.setActiveElements(tailElementSource.id);
  }

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sleep(fn: Function, delay: number, ...args: any[]) {
    return fn(...args);
  }

  removePosterFromVideoElements(elements: any[]) {
    const POSTER_TRACK = 100;
    return elements.filter((s: any) => s.track !== POSTER_TRACK) || [];
  }

  setVideoDimensionByAspectRatio(source: Record<string, any>) {
    const aspectRatio = this.currentVideo?.aspectRatio || '16:9';

    switch (aspectRatio) {
      case '1:1':
        source.width = source.height;
        break;
      case '9:16':
        source.width = source.height;
        source.height = (source.width * 16) / 9;
        break;
    }
    return source;
  }

  setElementsDuration(source: Record<string, any>) {
    source.elements.forEach((element: any) => {
      if (element.type === 'audio' && !element.duration) {
        const stockSongs = this.stockMusic.flatMap((m) => m.collection) || [];
        const ownSongs = this.story?.myAudios?.map((o) => o.song) || [];
        const allSongs = [...stockSongs, ...ownSongs];

        const song = allSongs.find((song) => song.url === element.source);
        if (song) {
          const trimStart = parseFloat(element.trim_start || '0');
          element.duration = parseFloat(song.customData.duration) - trimStart;
        }
      }
    });
    return source;
  }

  async finishVideo(
    resLevel?: 'high' | 'medium' | 'low' | 'original' | 'default',
  ): Promise<any> {
    const renderer = this.renderer;
    if (!renderer) {
      return;
    }

    let url = '';
    let webhook_url = '';
    if (process.env.REACT_APP_API_URL) {
      url = `${process.env.REACT_APP_API_URL}/api/render`;
      webhook_url = `${process.env.REACT_APP_API_URL}/api/webhooks/render`;
    }

    if (!url || !webhook_url) {
      throw Error('Render URL is not defined');
    }

    if (this.renderQueueing || this.isLoading || !renderer.state) {
      // wait when queued rendering is started and video is loaded
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (
            !videoCreator.renderQueueing &&
            !this.isLoading &&
            renderer.state
          ) {
            clearInterval(interval);
            resolve(null);
          }
        }, 300);
      });
    }

    if (this.isError) {
      throw Error('Error occurred during video loading, cannot render');
    }

    videoCreator.renderQueueing = true;
    videoCreator.renderingStatus = 'none';

    try {
      let source = renderer.getSource(renderer.state);
      this.currentVideo!.videoSource!.elements =
        this.removePosterFromVideoElements(
          this.currentVideo!.videoSource.elements,
        );
      const asFinal = false;
      const withRenderer = true;
      const resetTimeline = false;

      await this.saveStoryAndVideo(asFinal, withRenderer, resetTimeline);
      let videoId = this.currentVideo!.id!;
      this.renderVideoResLevel = resLevel || null;

      try {
        // do not await
        const captionService = new CaptionService();
        captionService.generateAllCaptions(videoId, 'Social Posts');
      } catch (error) {
        console.log('An error occurred when generating captions');
      }
      // only takes elements with volumeKeyPoints and sends them to the backend
      const filteredExtraElementData = Object.entries(
        this.currentVideo?.extraElementData || {},
      ).filter(
        ([id, element]) =>
          (element as ExtraElementData | null)?.volumeKeyPoints?.length,
      );

      const mappedExtraElementData = filteredExtraElementData.map(
        ([id, element]) => ({
          id,
          volumeKeyPoints: (element as ExtraElementData).volumeKeyPoints,
        }),
      );

      const recreatedElementsData = mappedExtraElementData.reduce(
        (obj, element) => {
          obj[element.id] = element;
          return obj;
        },
        {} as any,
      );

      //Remove temporary poster if applied
      source.elements = this.removePosterFromVideoElements(source.elements);
      if (resLevel && resLevel !== 'default') {
        source = this.replaceVideoSourceUrl(source, resLevel);
        const { width, height } = this.getDimensionsForResLevel(
          { width: source.width, height: source.height },
          resLevel,
        );
        source.width = width;
        source.height = height;
      }
      source = this.setVideoDimensionByAspectRatio(source);
      source = this.setElementsDuration(source);
      source.duration = this.duration;

      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source,
          extraElementData: recreatedElementsData,
          videoId,
          originalVideoUrl: this.story?.originalVideo.url,
          originalVideoUploadId: this.story?.originalVideo.id,
          storyId: this.story?.id,
          storyName: this.storyName,
          webhook_url: webhook_url,
          aspectRatio: this.currentVideo!.aspectRatio,
        }),
      });

      this.renderingStatus = 'rendering';
      setTimeout(this.pollRenderingStatus.bind(this), 15000);
      this.renderingVideoIds.push(this.currentVideo!.id!);
    } catch (err) {
      throw err;
    } finally {
      videoCreator.renderQueueing = false;
    }
  }

  async handleVideoRenderingStatusUpdate(video: VideoRenderingStatus) {
    if (
      (video.videoStatus === 'rendered' && !video.videoFilePrimary?.video) ||
      video.videoStatus === 'rendering'
    ) {
      return;
    }
    this.renderingStatus = video.videoStatus;
    let videoPublished: VideoClip | AssociatedVideo | undefined;

    // find video in story other videos or their associated videos with the same id as in video rendering status
    for (const storyVideo of this.story?.otherVideos!) {
      if (storyVideo.id === video.id) {
        videoPublished = storyVideo;
      } else {
        videoPublished = storyVideo.associatedVideos.find(
          (v) => v.id === video.id,
        );
      }
      if (videoPublished) break;
    }

    if (videoPublished) {
      videoPublished.videoFilePrimary = video.videoFilePrimary;
      videoPublished.isHidden = video.isHidden;
      videoPublished.lastActionJson = video.lastActionJson;
    }
    if (this.currentVideo?.id === video.id) {
      this.currentVideo!.videoFilePrimary = video.videoFilePrimary;
      this.currentVideo!.isHidden = video.isHidden;
      this.currentVideo!.lastActionJson = video.lastActionJson;
    }
    if (this.renderingStatus !== 'error') this.renderVideoResLevel = null;
    this.renderingVideoIds = this.renderingVideoIds.filter(
      (id) => id !== video.id,
    );

    this.loadCurrentVideoVersionsHistory(0);
    this.currentVideoVersionsTotalPages = undefined;
  }

  async pollRenderingStatus() {
    if (!this.renderingVideoIds.includes(this.currentVideo!.id!)) return;
    try {
      await this.fetchRenderResult({
        videoId: this.currentVideo!.id!,
        storyName: this.storyName!,
      });
      setTimeout(this.pollRenderingStatus.bind(this), 10000);
    } catch (e) {
      console.error('Error polling rendering status', e);
      setTimeout(this.pollRenderingStatus.bind(this), 15000);
    }
  }

  async fetchRenderResult(params: { videoId: string; storyName: string }) {
    const url = `${process.env.REACT_APP_API_URL}/api/render/update-dato`;
    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
    } catch (err: any) {
      console.error('Error fetching render result', err);
    }
  }

  async pollTranscriptionId() {
    try {
      const queryResult: {
        story: Pick<Story, 'transcription' | 'id'>;
      } = await videoCreator.gqlClient!.request(
        ORIGINAL_VIDEO_TRANSCRIPTION_QUERY,
        {
          id: this.story?.id,
        },
      );

      if (!queryResult.story.transcription) {
        this.transcriptionLoadingStatus = 'none';
      } else if (
        !queryResult.story.transcription.elementsJson &&
        queryResult.story.transcription.jobStatus === 'FAILED'
      ) {
        this.transcriptionLoadingStatus = 'generation_failed';
      } else if (!queryResult.story.transcription.elementsJson) {
        setTimeout(this.pollTranscriptionId.bind(this), 10000);
      } else {
        this.transcriptionId = queryResult.story.transcription.elementsJson.id;
        await this.loadTranscription();
        await this.loadOriginalWaveformData();
      }
    } catch (e) {
      console.error('Error polling transcription', e);
      setTimeout(this.pollTranscriptionId.bind(this), 10000);
    }
  }

  get canUndo() {
    return this.undoStack.length > 0;
  }

  getUndoLabel() {
    return this.videoSnapshots.at(-1)?.label;
  }

  getRedoLabel() {
    return this.redoSnapshots.at(-1)?.label;
  }

  get canRedo() {
    return this.redoStack.length > 0;
  }

  get isVideoCreatorReady() {
    return !this.isLoading && this.stateReady;
  }

  //TODO move punchlist related functions to separate class (AIProducer?)
  async attachPunchListPhotosToStory() {
    const storyDTO: StoryDTO = { ...this.story! };
    const srcUrls = (
      this.state?.elements?.map((e) => {
        if (e.source.type === 'image') return e.source.source;
      }) || []
    ).filter(Boolean);

    if (this.photoDataForDato && Object.values(this.photoDataForDato)) {
      for (let [id, blobData] of Object.entries(this.photoDataForDato)) {
        if (!srcUrls?.includes(blobData.url)) {
          continue;
        }

        const idx = this.punchListData?.findIndex((p) => p.id === id);
        if (idx && idx > -1) {
          if (!this.punchListData?.length) continue;
          //todo add to story

          if ('uploadId' in blobData && blobData.uploadId) {
            storyDTO.aiPhotos?.push({ id: blobData.uploadId });
            continue;
          }

          const line = this.punchListData[idx].line;
          const fileName = line.split(' ').join('_').toLowerCase().slice(0, 30);

          const newPhotoData = {
            ...blobData,
            fileName,
            alt: line,
            title: line,
            metaData: {
              alt: line,
              title: line,
              custom_data: {},
            },
          };

          const newUpload =
            await this.assetRepository?.uploadFile(newPhotoData);

          this.punchListData[idx] = {
            ...this.punchListData[idx],
            artifactSrc: newUpload!.url,
          };

          if (blobData.type === 'ai') {
            storyDTO.aiPhotos?.push(newUpload!);
          } else if (blobData.type === 'stock') {
            storyDTO.storyAssets?.push(newUpload!);
          }
        } else {
          //todo add to story
          if (!('fileName' in blobData)) continue;

          const newPhotoData = {
            ...blobData,
            alt: blobData.alt || '',
            title: blobData.title || '',
          };

          const newUpload =
            await this.assetRepository?.uploadFile(newPhotoData);

          if (blobData.type === 'stock') {
            storyDTO.storyAssets?.push(newUpload!);
          } else if (blobData.type === 'ai') {
            storyDTO.aiPhotos?.push(newUpload!);
          }
        }
      }
    }

    return storyDTO;
  }

  async generateMissingPunchListPhotos() {
    //create snapshot before generating
    if (!this.punchListData?.length) return;
    const bareList = this.punchListData?.filter((item) => !item.artifactSrc);
    const gpt_service = new ChatGPTService();
    const arborStylePrompt =
      await gpt_service.fetchAiPrompts('Arbor Photo Style');
    const ai_generated = await Promise.all(
      //TODO refactor, upload image on save only?
      bareList.map(async (item) => {
        let dallePrompt = `${item.dallePrompt} ${
          arborStylePrompt?.description || ''
        }`;

        const data = await retry(
          () => generateImagesWithGPT(dallePrompt, 1),
          10,
        );

        if (data.length) {
          const title = item?.line?.slice(0, 30) || '';
          const fileName = title?.toLowerCase()?.split(' ').join('_') || '';

          const upload = await this.assetRepository?.uploadFile({
            url: data[0].url,
            type: 'ai',
            fileName,
            alt: title,
            title,
          });

          if (upload) {
            return {
              id: item.id,
              url: upload.url,
              uploadId: upload.id,
            };
          }
        }
      }),
    );

    const filtered_ai_generated = ai_generated.filter(Boolean);
    const maxTrack = this.getMaxTrack();
    let foundItemsCounter = 0;

    const newList: PunchListItem[] = await Promise.all(
      this.punchListData.map(async (punchListItem) => {
        const item = filtered_ai_generated.find(
          (c) => c!.id === punchListItem.id,
        );

        if (item) {
          const { id, url, uploadId } = item;
          runInAction(async () => {
            this.photoDataForDato = {
              ...this.photoDataForDato,
              [id!]: {
                type: 'ai',
                url: url,
                cat: 'feeling_lucky',
                uploadId,
              },
            };
          });
          let time = punchListItem.startTime || foundItemsCounter * 30; //todo clarify

          await this.findOrReplaceInTimeline(id!, url, maxTrack + 1, time);
          foundItemsCounter++;
          return {
            ...punchListItem,
            artifactSrc: item.url,
          };
        }
        return punchListItem;
      }),
    );

    runInAction(() => {
      this.punchListData = newList;
    });
  }

  findOrReplaceInTimeline = async (
    id: string,
    source: string,
    track: number | null = null,
    time: number | null = null,
  ) => {
    const freeTrack = this.getFreeMediaTrack('image', 8);
    const animations = [
      {
        start_scale: '100%',
        end_scale: '110%',
        x_anchor: '50%',
        y_anchor: '50%',
        fade: false,
        scope: 'element',
        easing: 'linear',
        type: 'scale',
        arbor_subType: 'zoomIn',
      },
    ];

    const hasId = this.state!.elements?.some((e) => e.source.id === id);
    if (hasId) {
      await this.renderer?.applyModifications({
        [`${id}.source`]: source,
        [`${id}.animations`]: animations,
      });
      this.createDefaultUndoPoint();
    } else {
      await this.createElement({
        id,
        type: 'image',
        source,
        duration: '8 s',
        ...(time && { time }),
        ...(freeTrack && { track: freeTrack }),
        autoplay: true,
        animations,
      });
    }
  };

  toggleTrackFrameLock = (track: number) => {
    this.frameLockedTracks = this.frameLockedTracks.includes(track)
      ? this.frameLockedTracks.filter((t) => t !== track)
      : [...this.frameLockedTracks, track];
  };

  removePunchListItem = async (punchListId: string) => {
    const newPunchListData = this.punchListData?.filter(
      (item) => item.id !== punchListId,
    );
    if (newPunchListData) {
      this.punchListData = newPunchListData;
    }
    videoCreator.removeAPhotoHighlightTranscriptChange(punchListId);
    await this.deleteElementWithTranscription(punchListId);
  };

  insertPunchListAtIndex = (
    newPunchListData: PunchListItem[],
    punchListData = this.punchListData,
  ) => {
    const sortedPunchList = newPunchListData.sort(
      (a, b) => a.transcriptPosition.endIndex - b.transcriptPosition.endIndex,
    );
    const lastPunchList = sortedPunchList[sortedPunchList.length - 1];
    const endIndex = lastPunchList.transcriptPosition.endIndex;

    const punchListLength = punchListData!.length;
    if (
      !punchListLength ||
      endIndex > punchListData![punchListLength - 1].transcriptPosition.endIndex
    ) {
      const existingPunchListData = punchListData || [];
      punchListData = [...existingPunchListData, ...sortedPunchList];
    } else {
      const indexToInsert = punchListData?.findIndex(
        (p) => p.transcriptPosition.endIndex > endIndex,
      );
      const punchListBefore = punchListData?.slice(0, indexToInsert!) || [];
      const punchListAfter = punchListData?.slice(indexToInsert!) || [];
      punchListData = [
        ...punchListBefore,
        ...sortedPunchList,
        ...punchListAfter,
      ];
    }
    return punchListData;
  };

  getPunchListTrack = () => {
    let punchListItemId: string | undefined = undefined;
    let extraElementData = this.currentVideo?.extraElementData || {};
    if (this.punchListData?.length) {
      punchListItemId = this.punchListData[0].id;
    } else if (Object.keys(extraElementData).length) {
      punchListItemId = Object.keys(extraElementData).find(
        (key) =>
          (extraElementData[key] as ExtraElementData | null)?.punchListData?.id,
      );
    }

    if (punchListItemId) {
      const element = videoCreator.renderer?.state?.elements?.find(
        (el) => el.source.id === punchListItemId,
      );

      return element?.track;
    }
  };

  addCutPhotoToPunchlist = async (
    existingId: string,
    head: {
      id: string;
      transcriptPosition: Record<'startIndex' | 'endIndex', number>;
    },
    tail: {
      id: string;
      transcriptPosition: Record<'startIndex' | 'endIndex', number>;
    },
  ) => {
    const idx = this.punchListData?.findIndex((p) => p.id === existingId);
    if (idx !== undefined && idx > -1) {
      const punchlistItem = this.punchListData![idx];
      const headItem = { ...punchlistItem, ...head };
      const tailItem = { ...punchlistItem, ...tail };
      this.punchListData!.splice(idx, 1, headItem, tailItem);
    }
  };

  addPhotoToPunchList = async (
    text: string,
    photo: string,
    description: string,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
  ) => {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;

      const duration = endTime - startTime;
      const maxTrack = this.getMaxTrack();
      const currTrack = maxTrack + 1;
      // const punchListTrack = this.getPunchListTrack() || currTrack;
      const freeTrack = this.getFreeMediaTrack('image', duration, startTime);
      const punchListTrack = freeTrack || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type: 'punch_list',
        sub_type: 'manual',
        description,
        line: text,
        artifactSrc: photo,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );

      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  };

  addItemToPunchList = async (
    text: string,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
  ) => {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;
      this.sidebarOptions = SidebarOption.aiProducer;

      const duration = Math.min(8, endTime - startTime);
      const maxTrack = this.getMaxTrack();
      const currTrack = maxTrack + 1;
      // const punchListTrack = this.getPunchListTrack() || currTrack;
      const freeTrack = this.getFreeMediaTrack('image', duration, startTime);
      const punchListTrack = freeTrack || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type: 'punch_list',
        sub_type: 'manual',
        description: '',
        line: text,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );

      const gptService = new ChatGPTService();

      const punchListItem: PunchListItem =
        await gptService.generatePunchListItem(text);

      if (punchListItem) {
        const updatedPunchList = this.punchListData!.map((p) =>
          p.id === punchListId
            ? {
                ...p,
                ...punchListItem,
              }
            : p,
        );
        this.punchListData = updatedPunchList;
      }

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  };

  async addPunchListItemToTimeline(
    punchListItem: PunchListItem,
    duration: number,
    startTime: number,
    endTime: number,
    currTrack: number,
  ) {
    await videoCreator.createElement({
      id: punchListItem.id,
      type: 'image',
      source: punchListItem.artifactSrc || '',
      duration: duration,
      autoplay: true,
      fit: 'cover',
      smart_crop: true,
      track: currTrack, //todo remove hardcoding
      time: startTime,
      animations: [
        {
          start_scale: '100%',
          end_scale: '110%',
          x_anchor: '50%',
          y_anchor: '50%',
          fade: false,
          scope: 'element',
          easing: 'linear',
          type: 'scale',
          arbor_subType: 'zoomIn',
        },
      ],
    });
    punchListItem.startTime = startTime;
    punchListItem.endTime = endTime;
    punchListItem.duration = duration;
  }

  setAiImageFeatureFlag(flagString: string) {
    if (!this.story?.id) return;
    if (this.datoContext.environment !== 'production') return;
    const flags = flagString?.trim()?.split(',') || [];
    if (!flags.includes(this.story.id)) {
      this.disableAiImageGenerate = true;
    }
  }

  getDefaultSource(params?: { videoRes: 'low' | 'medium' | 'high' }) {
    const id = uuid();
    const videoRes = params?.videoRes;

    let sourceUrl = this.getVideoSourceUrlForResLevel(videoRes || 'original');

    return {
      output_format: 'mp4',
      width: 1280,
      height: 720,
      frame_rate: '24 fps',
      // duration: this.originalVideoDuration, - let duration autoadjust
      elements: [
        {
          id,
          duration: this.originalVideoDuration,
          track: 1,
          time: 0,
          type: 'video',
          source: sourceUrl,
          autoplay: true,
          // locked: true,
        },
      ],
    };
  }

  getFreeMediaTrack(
    type: 'audio' | 'video' | 'image',
    mediaDuration: number,
    currTime = this.time,
  ) {
    const tracks = this.renderer?.state?.elements?.filter((el) => {
      if (type !== 'image')
        return (
          el.source.type === type && !this.isOriginalVideoElement(el.source)
        );
      return this.isImageElement(el) && !this.isLogoElement(el);
    });

    const mediaSpan = currTime + mediaDuration;

    const overlappedTracks =
      tracks
        ?.filter((track) => {
          const time = track.source.time;
          const duration = track.duration;
          const timeSpan = time + duration;
          if (
            (time <= currTime && timeSpan >= currTime) ||
            (time <= mediaSpan && timeSpan >= mediaSpan)
          ) {
            return true;
          }
          return false;
        })
        ?.map((t) => t.track) || [];

    return tracks?.find((t) => !overlappedTracks.includes(t.track))?.track;
  }

  fixTranscription() {
    const source = this.renderer?.getSource();
    const state = this.renderer?.state;
    if (!source || !state) {
      throw Error('No source or state found to fix transcription');
    }
    // todo save before fixing
    const restoredTranscriptionChanges = restoreTranscriptionFromVideoSource(
      state.elements.filter((el) => this.isOriginalVideoElement(el.source)),
      this.originalTranscription!,
    );

    const oldTranscriptElements = [...this.finalTranscriptionElements!];

    this.videoTranscriptionProcessor.applyChangesToOriginalTranscription(
      restoredTranscriptionChanges,
    );
    this.videoTranscriptionProcessor.reapplyEdits(oldTranscriptElements);
    this.resetPhotoHighlights();
    this.refreshKaraokeElements();
  }

  handleUpdatemyAudioMetadata(audios: myAudio[]) {
    const existingAudios = this.story?.myAudios || [];
    const updatedmyAudios = existingAudios.map((audio) => {
      const currAudio = audios.find((a) => a.id === audio.id);
      console.log('currAudio', currAudio);
      if (currAudio) {
        if (currAudio.song.url && !this.audioTracksData[currAudio.song.url]) {
          videoCreator.loadWaveformForSource({
            source: currAudio.song.url,
            name: currAudio.song.title,
          });
        }
        return currAudio;
      }
      return audio;
    });

    const isCompleted = audios.every(
      (audio) => audio.metadataStatus === 'success' && audio.waveformUploadId,
    );

    const isFailed = audios.some((audio) => audio.metadataStatus === 'failed');

    if (isCompleted) {
      videoCreator.toastState = {
        state: 'success',
        message: 'Audio Uploaded',
      };
    }

    if (isFailed) {
      videoCreator.toastState = {
        state: 'error',
        message: 'Audio Upload failed, please contact arbor',
      };
    }

    this.story!.myAudios = updatedmyAudios;
  }

  addUnsaveVideoAction(): void {
    this.addVideoAction({
      editor: this.currentEditor,
      type: 'unsave',
      date: new Date().getTime(),
    });
  }

  private addVideoAction(newVideoAction: VideoAction): void {
    if (!this.currentVideo) {
      return;
    }
    this.currentVideo!.lastActionJson = newVideoAction;
    for (const storyVideo of this.story?.otherVideos || []) {
      if (storyVideo.id === this.currentVideo.id) {
        storyVideo.lastActionJson = newVideoAction;
      }
    }
  }

  async findOneStory(id: string): Promise<Story | null> {
    const story = await this.storyRepository?.findOne(id);

    if (story && this.currentUserType === 'external') {
      story.otherVideos = story.otherVideos.filter((c) => c.isClientReady);
    }

    return story || null;
  }

  async findManyStories(albumId: string) {
    let stories =
      (await this.storyRepository?.findMany(albumId, {
        includeDrafts: true,
        excludeInvalid: false, // newly added stories may not have originalVideo.video field available yet, which will throw an error
        environment: this.datoContext.environment,
      })) || [];

    if (this.currentUserType === 'external') {
      stories = stories.map((s) => ({
        ...s,
        visibleVideos: s.otherVideos.filter((v) => v.isClientReady),
      }));
    }

    stories.sort((a, b) => {
      // show unpublished stories first
      const aDate = a._publishedAt ? new Date(a._publishedAt) : new Date();
      const bDate = b._publishedAt ? new Date(b._publishedAt) : new Date();
      return bDate.getTime() - aDate.getTime();
    });

    return stories;
  }

  // Condensed debug data set for displaying in DebugMode.
  getDebugContent() {
    const videoSource = this.currentVideo?.videoSource ?? {};
    const { elements, ...videoSourceWithoutElements } = videoSource;

    const {
      transcriptionChanges,
      transcriptionSnapshot,
      ...currentVideoWithoutTranscriptions
    } = this.currentVideo ?? {};

    return {
      time: this.time,
      duration: this.duration,
      currentVideo: {
        ...currentVideoWithoutTranscriptions,
        videoSource: videoSourceWithoutElements,
      },
      // currentVideoVersions: this.currentVideoVersions,
    };
  }

  async createStory(
    story: Partial<StoryDTO>,
    originalVideoUploadId?: string,
  ): Promise<Story> {
    if (!this.storyRepository) {
      throw new Error('createStory: no story repo');
    }
    return await this.storyRepository.create(story, originalVideoUploadId);
  }

  async updateStory(
    story: Partial<StoryDTO> & { id: string },
    originalVideoUploadId?: string,
  ): Promise<Story> {
    return await this.storyRepository!.update(story, originalVideoUploadId);
  }

  async softDeleteStory(storyId: string) {
    if (!this.storyRepository) {
      throw new Error('deleteStory: no story repo');
    }
    if (videoCreator.organization) {
      return await this.storyRepository.removeReferenceFromShowcase(
        storyId,
        videoCreator.organization.id,
      );
    }
  }

  async upsertStoryTeller({
    name,
    title,
  }: {
    name: string;
    title?: string;
  }): Promise<Person> {
    if (!this.gqlClient) {
      throw new Error('findOrCreateStoryTeller: no gqlClient');
    }
    const response = (await this.gqlClient.request(PERSON_BY_NAME_QUERY, {
      name,
    })) as { allPeople: (Person & { id: string })[] };
    if (response.allPeople.length) {
      const person = response.allPeople[0];
      if (person.title !== title && title) {
        this.datoClient?.items.update(person.id, {
          title,
        });
        person.title = title;
      }
      return person;
    }

    if (!this.datoClient) {
      throw new Error('findOrCreateStoryTeller: no datoClient');
    }
    const itemType = await this.datoClient.itemTypes.find('person');
    const newPerson = await this.datoClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      name,
      title,
    });
    return {
      id: newPerson.id,
      name: newPerson.name,
      title: newPerson.title,
    } as Person;
  }
}

export const videoCreator = new VideoCreatorStore();
