import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import PrimaryActionButton from './PrimaryActionButton';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { FileData, myAudio } from '../../types.ts/story';
import { observer } from 'mobx-react-lite';
import SongCollection from './SongCollection';
import SpinningLoading from '../SpinningLoading';
import MusicIcon from '../../svgs/MusicIcon';
import MusicSongItem from './MusicSongItem';
import SearchInput from './SearchInput';
import { checkWordsInString } from '../../utility/general';
import CloudIcon from '../../svgs/CloudIcon';

const MUSIC_SECTION_WIDTH_PX = 300;

type Props = {
  setCurrentSong: Dispatch<SetStateAction<string>>;
  handleAddToTrack: (
    url: string,
    songName: string,
    mediaDuration: string,
  ) => Promise<void>;
  audioRef: MutableRefObject<HTMLAudioElement | null>;
  currentSong: string;
  preloadAudio: (url: string) => void;
};

const MyAudioLibrary = observer((props: Props) => {
  const {
    setCurrentSong,
    handleAddToTrack,
    audioRef,
    currentSong,
    preloadAudio,
  } = props;
  const myAudios = videoCreator.story?.myAudios || [];
  const [audios, setAudios] = useState<myAudio[]>(myAudios);
  const updatingMetadata = audios.some(
    (a) => a.metadataStatus === 'generating' && !a.waveformUploadId,
  );

  useEffect(() => {
    const audios = videoCreator.story?.myAudios || [];
    if (audios.length) setAudios(audios);
  }, [videoCreator.story?.myAudios]);

  useEffect(() => {
    for (const audio of myAudios) {
      const song = audio.song;
      preloadAudio(song.url);
      if (!videoCreator.audioTracksData[song.url]) {
        videoCreator.loadWaveformForSource({
          source: song.url,
          name: song.title,
        });
      }
    }
  }, [myAudios]);

  async function uploadAudio(e: ChangeEvent<HTMLInputElement>) {
    const files = [...(e.target.files || [])].slice(0, 3);
    console.log('files', files);

    if (!files.length) return;
    try {
      videoCreator.uploadingMyAudio = true;

      const itemType =
        await videoCreator.datoClient?.itemTypes.find('my_audio');

      const uploadResults = await Promise.all(
        files.map(async (file) => {
          const fileName = file.name;
          const newFileData: FileData & { fileName: string } = {
            type: 'audio',
            file,
            fileName,
            alt: '',
            title: '',
          };

          const upload =
            await videoCreator.assetRepository?.uploadFile(newFileData);
          console.log('upload', upload);

          const savedAudio = await videoCreator.datoClient?.items.create({
            item_type: { type: 'item_type', id: itemType!.id },
            song: { upload_id: upload!.id },
            metadata_status: 'generating',
          });

          return {
            id: savedAudio!.id,
            duration: null,
            waveformUploadId: null,
            title: '',
            metadataStatus: 'generating' as 'generating',
            song: {
              id: upload!.id,
              url: upload!.url,
              title: '',
              customData: {} as any,
              tags: [],
            },
          };
        }),
      );

      const allAudios = [
        ...uploadResults,
        ...(videoCreator.story?.myAudios || []),
      ];
      videoCreator.story = {
        ...videoCreator.story!,
        myAudios: allAudios,
      };
      setAudios(allAudios);

      await videoCreator.updateStory(videoCreator.story);
      videoCreator.uploadingMyAudio = false;
    } catch (error) {
      videoCreator.uploadingMyAudio = false;
      videoCreator.toastState = {
        state: 'error',
        message: 'Audio Upload failed, please contact arbor',
      };
    }
  }

  const handleSearchMusic = (text: string) => {
    if (!text) return setAudios(myAudios);

    const filteredAudios = myAudios.filter((audio) => {
      return (
        checkWordsInString(audio.title, text) ||
        checkWordsInString(audio.song.title, text) ||
        (audio.song.customData.artist &&
          checkWordsInString(audio.song.customData.artist, text)) ||
        checkWordsInString(audio.song.customData.songName, text)
      );
    });
    setAudios(filteredAudios);
  };

  const renderMyMusic = () => (
    <Collection>
      <Songs style={{ width: '100%' }}>
        <ButtonWrapper>
          <AddMusicButton type="button">
            <File
              type="file"
              title=""
              multiple
              accept="audio/mp3, audio/wav"
              onChange={uploadAudio}
            />
            <CloudIcon
              width="18px"
              height="18px"
              strokeWidth="3"
              strokeColor="#03041A"
            />
            Upload Audio
          </AddMusicButton>
        </ButtonWrapper>
        <WarningNote>Upload up to three files at a time</WarningNote>
        <HorizontalLine />
        {(videoCreator.uploadingMyAudio || updatingMetadata) && (
          <SpinningLoading
            positionTop="100px"
            Ico={<MusicIcon strokeColor="#17c964" />}
            text={
              <AnimationText>
                <span>Uploading audio</span>
              </AnimationText>
            }
          />
        )}
        {Boolean(audios.length) && (
          <SongCollection onAddToTrack={handleAddToTrack}>
            {({ handleSongSelect, isSongSelected }) =>
              audios
                ?.filter((m) => m.song.customData.duration)
                .map((m) => {
                  return (
                    <MusicSongItem
                      key={m.id}
                      audioRef={audioRef}
                      song={m.song}
                      title={m.title}
                      generatingMetadata={
                        m.metadataStatus === 'generating' && !m.waveformUploadId
                      }
                      currentSong={currentSong}
                      setCurrentSong={setCurrentSong}
                      mood=""
                      onSongClick={handleSongSelect}
                      customTimelineScale={
                        (MUSIC_SECTION_WIDTH_PX /
                          parseFloat(m.song.customData.duration)) *
                        1
                      }
                      isHighlighted={isSongSelected(m.song)}
                    />
                  );
                })
            }
          </SongCollection>
        )}
      </Songs>
    </Collection>
  );
  return (
    <>
      <SearchInput
        placeholder="Search music"
        handleAction={handleSearchMusic}
        radius="20px"
      />
      {renderMyMusic()}
    </>
  );
});

export default MyAudioLibrary;

const MusicCollection = styled.div`
  margin-top: 20px;
`;

const Collection = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Songs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-transform: capitalize;
  .no-content {
    text-transform: none;
  }
`;

const AddMusicButton = styled(PrimaryActionButton)`
  position: relative;
  height: 40px;
  font-size: 14px;
  svg {
    margin-right: 8px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  box-shadow: 0px 1px 0 0 #030419;
  background: #030419;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
`;

const File = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #333;
  margin: 10px 0;
`;

const AnimationText = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: none;
`;

const WarningNote = styled.span`
  color: #848484;
  font-family: Inter;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
`;
