import { v4 as uuid } from 'uuid';
import { videoCreator } from '../stores/VideoCreatorStore';

type ProductionInfo = {
  uuid: string;
};

type OutputFile = {
  format: string;
  ending: string;
  suffix: string;
  filename: string;
  split_on_chapters: boolean;
  bitrate: number;
  mono_mixdown: boolean;
  size: number;
  size_string: string;
  download_url: string;
  outgoing_services: string[];
  checksum: string;
};

type ProductionStatus =
  | {
      status: 1;
      status_string: 'Waiting';
    }
  | {
      status: 2;
      status_string: 'Error';
    }
  | {
      status: 3;
      status_string: 'Done';
    }
  | {
      status: 9;
      status_string: 'Incomplete'; // happens if file upload failed
    }
  | {
      status: number;
      status_string: string;
    };

export type ProductionResult = {
  output_files: OutputFile[];
};

const STATUS_CHECK_INTERVAL = 15 * 1000;

export async function startProductionToRemoveMusicFromVideo({
  inputFile,
  onProductionDone,
  onError,
}: {
  inputFile: File;
  onProductionDone: (p: ProductionResult) => void;
  onError?: (e: any) => void;
}) {
  try {
    const info = await makeRequestToAuphonic<ProductionInfo>(
      'productions',
      'POST',
      {
        preset: 'rz6RvGUBRDy495mQ8K4PNC',
        input_file: await uploadFileToS3(inputFile),
        action: 'start',
      },
    );
    const productionId = info.uuid;
    setTimeout(() => {
      checkIsProductionDone(productionId, onProductionDone, onError);
    }, STATUS_CHECK_INTERVAL);
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}

async function checkIsProductionDone(
  productionId: string,
  onProductionDone: (p: ProductionResult) => void,
  onError?: (e: any) => void,
) {
  try {
    const { status, status_string } = await getProductionStatus(productionId);
    if (status === 3) {
      const result = await getProductionResult(productionId);
      onProductionDone(result);
    } else if (status === 2 || status === 9) {
      throw new Error(status_string);
    } else {
      setTimeout(() => {
        checkIsProductionDone(productionId, onProductionDone, onError);
      }, STATUS_CHECK_INTERVAL);
    }
  } catch (error) {
    if (onError) {
      onError(error);
    }
  }
}

async function getProductionResult(
  productionId: string,
): Promise<ProductionResult> {
  return await makeRequestToAuphonic<ProductionResult>(
    `production/${productionId}`,
  );
}

async function getProductionStatus(
  productionId: string,
): Promise<ProductionStatus> {
  return await makeRequestToAuphonic<ProductionStatus>(
    `production/${productionId}/status`,
  );
}

const API_ORIGIN = process.env.REACT_APP_API_URL;

async function makeRequestToAuphonic<T>(
  endpoint: string,
  method: 'GET' | 'POST' = 'GET',
  body?: any,
): Promise<T> {
  const response = await fetch(`${API_ORIGIN}/api/forward/auphonic`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...body,
      method,
      endpoint,
    }),
  });
  return (await response.json()).data;
}

async function uploadFileToS3(file: File): Promise<string> {
  const response = await fetch(`${API_ORIGIN}/api/aws-presigned-url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: bucketPathKey(),
    }),
  });
  const { signedPutUrl, signedGetUrl } = await response.json();

  await fetch(signedPutUrl, {
    method: 'PUT',
    body: file,
  });

  return signedGetUrl;

  function bucketPathKey(): string {
    let fileKey: string;
    try {
      const fileExtension = file.name.split('.').slice(-1)[0];
      fileKey = `${uuid()}.${fileExtension}`;
    } catch (error) {
      fileKey = file.name;
    }

    return `externalUploads/${
      videoCreator.organization?.id || 'no_org'
    }/${fileKey}`;
  }
}

export async function downloadFileFromS3(filename: string): Promise<Blob> {
  const response = await fetch(`${API_ORIGIN}/api/aws-presigned-url`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      key: filename,
      bucket: 'auphonic-integration',
    }),
  });
  const { signedGetUrl } = await response.json();
  const fileResponse = await fetch(signedGetUrl);
  return await fileResponse.blob();
}
