import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { runInAction } from 'mobx';
import { videoCreator } from '../../stores/VideoCreatorStore';
import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import AutoResizeableMultilineInput from './AutoResizeableMultilineInput';
import { getDocumentHeight } from '../../utility/general';
import applyImageOptimizations from '../../utility/applyImageOptimizations';
import { observer } from 'mobx-react-lite';
import { Artifact, Showcase } from '../../types.ts/story';

type Props = {
  item: {
    id: string;
    src: string;
    title?: string;
    description: string;
    thumbnailUrl?: string;
  };
  type:
    | 'storyArtifacts'
    | 'storyArtifactsVideo'
    | 'organizationArtifacts'
    | 'organizationArtifactsVideo'
    | 'organizationLogos';
  isSelected: boolean;
  handleClick: () => void;
  handleRemove: () => void;
  callback?: (artifact?: Artifact[], showcases?: Showcase[]) => void;
};
const ENLARGED_PHOTO_HEIGHT = 345;
const SMALL_PHOTO_HEIGHT = 70;

const FileListItem = observer((props: Props) => {
  const {
    item,
    type,
    isSelected = false,
    callback,
    handleClick = () => {},
  } = props;
  const [isActionClicked, setIsActionClicked] = useState(false);
  const [description, setDescription] = useState(item.description);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [enlargedState, setEnlargedState] = useState<DOMRect | null>(null);
  const [enlargedImageActualWidth, setEnlargedImageActualWidth] = useState<
    number | null
  >(null);
  useOutsideAlerter(buttonRef, () => {
    setIsActionClicked(false);
  });

  const handleUpdateDescription = async () => {
    if (item.description === description) return;

    switch (type) {
      case 'storyArtifacts':
        videoCreator.story!.storyArtifacts =
          videoCreator.story?.storyArtifacts?.map((f) =>
            f.id === item.id ? { ...f, title: description } : f,
          );
        callback && callback(videoCreator.story!.storyArtifacts!);
        await videoCreator.updateStory(videoCreator.story!);
        break;
      case 'storyArtifactsVideo':
        videoCreator.story!.storyArtifactsVideo =
          videoCreator.story?.storyArtifactsVideo?.map((f) =>
            f.id === item.id ? { ...f, title: description } : f,
          );
        props.callback &&
          props.callback(videoCreator.story!.storyArtifactsVideo!);
        await videoCreator.updateStory(videoCreator.story!);
        break;
      case 'organizationArtifacts':
        const idx = videoCreator.story?._allReferencingShowcases?.findIndex(
          (s) => s.organizationArtifacts?.some((a) => a.id === item.id),
        );
        if (idx !== undefined && idx > -1) {
          videoCreator.story!._allReferencingShowcases[
            idx
          ].organizationArtifacts =
            videoCreator.story!._allReferencingShowcases[
              idx
            ].organizationArtifacts?.map((f) =>
              f.id === item.id ? { ...f, title: description } : f,
            );
          props.callback &&
            props.callback(
              undefined,
              videoCreator.story?._allReferencingShowcases,
            );
          await videoCreator.albumRepository?.update(
            videoCreator.story!._allReferencingShowcases[idx],
          );
        }
        break;
      case 'organizationLogos': {
        const idx = videoCreator.story?._allReferencingShowcases?.findIndex(
          (s) => s.organizationLogos?.some((a) => a.id === item.id),
        );
        if (idx !== undefined && idx > -1) {
          videoCreator.story!._allReferencingShowcases[idx].organizationLogos =
            videoCreator.story!._allReferencingShowcases[
              idx
            ].organizationLogos?.map((f) =>
              f.id === item.id ? { ...f, title: description } : f,
            );
          props.callback &&
            props.callback(
              undefined,
              videoCreator.story?._allReferencingShowcases,
            );
          await videoCreator.albumRepository?.update(
            videoCreator.story!._allReferencingShowcases[idx],
          );
        }
        break;
      }
      case 'organizationArtifactsVideo':
        const index = videoCreator.story?._allReferencingShowcases?.findIndex(
          (s) => s.organizationArtifactsVideo?.some((a) => a.id === item.id),
        );
        if (index !== undefined && index > -1) {
          videoCreator.story!._allReferencingShowcases[
            index
          ].organizationArtifactsVideo =
            videoCreator.story!._allReferencingShowcases[
              index
            ].organizationArtifactsVideo?.map((f) =>
              f.id === item.id ? { ...f, title: description } : f,
            );
          await videoCreator.albumRepository?.update(
            videoCreator.story!._allReferencingShowcases[index],
          );
        }
        break;
    }

    await videoCreator.assetRepository?.update(item.id, {
      title: description,
      alt: description,
    });
  };

  const isPhotoType =
    type === 'storyArtifacts' ||
    type === 'organizationArtifacts' ||
    type === 'organizationLogos';

  const renderEnlargedImage = () => {
    const top = Math.max(
      enlargedState!.top -
        ENLARGED_PHOTO_HEIGHT / 2 +
        SMALL_PHOTO_HEIGHT / 2 -
        8,
      0,
    );
    const arrowPosCorrection = () => {
      const winHeight = getDocumentHeight() - ENLARGED_PHOTO_HEIGHT;
      if (winHeight >= top) {
        return 50;
      }
      const diff = top - winHeight;
      const percentageDiff = (diff / ENLARGED_PHOTO_HEIGHT) * 100;
      return 50 + percentageDiff;
    };

    const containerActualWidth = (enlargedImageActualWidth || 0) + 8;
    return (
      <EnlargedImageContainer
        onClick={(e) => e.stopPropagation()}
        top={Math.max(
          enlargedState!.top -
            ENLARGED_PHOTO_HEIGHT / 2 +
            SMALL_PHOTO_HEIGHT / 2 -
            8,
          0,
        )}
        left={enlargedState!.left - containerActualWidth - 10}
        actualWidth={containerActualWidth}
        arrowPos={arrowPosCorrection()}
        className="enlarged"
        isVisible={!!enlargedImageActualWidth}
      >
        <EnlargedImage
          src={
            item.thumbnailUrl ||
            applyImageOptimizations(item.src, {
              height: 337,
            })
          }
          onLoad={(e) => {
            setEnlargedImageActualWidth(
              e.currentTarget.getBoundingClientRect().width,
            );
          }}
          actualWidth={enlargedImageActualWidth}
        />
      </EnlargedImageContainer>
    );
  };

  return (
    <Entry
      ref={containerRef}
      key={item.id}
      onClick={handleClick}
      isSelected={isSelected}
    >
      <Wrapper
        className="target"
        hasImage={!!item.src}
        onClick={() => {
          runInAction(async () => {
            videoCreator.setActiveElements(item.id!.toString());
          });
        }}
        onMouseEnter={(e) => {
          if (isPhotoType) {
            setEnlargedState(e.currentTarget.getBoundingClientRect());
          }
        }}
        onMouseMove={(e) => {
          if (isPhotoType && !enlargedState) {
            setEnlargedState(e.currentTarget.getBoundingClientRect());
          }
        }}
        onMouseLeave={() => {
          setEnlargedImageActualWidth(null);
          setEnlargedState(null);
        }}
      >
        {item.src ? (
          <>
            {isPhotoType || item.thumbnailUrl ? (
              <Image
                src={
                  item.thumbnailUrl ||
                  applyImageOptimizations(item.src, {
                    width: 100,
                  })
                }
              />
            ) : (
              <Video src={item.src} />
            )}
          </>
        ) : (
          <TimelinePhotoIcon strokeColor="#828282" />
        )}
      </Wrapper>
      {enlargedState && renderEnlargedImage()}
      <Text>
        {item.title && <div className="quote">{item.title}</div>}
        <TextDescription>
          {/* <div className="text">{item.description}</div> */}
          <AutoResizeableMultilineInput
            customStyles={{
              color: '#D7D7E1',
              fontSize: '10px',
              fontWeight: '300',
              lineHeight: '12px',
              paddingLeft: '5px',
              paddingRight: '5px',
            }}
            hasOutline={false}
            hasBorder={false}
            borderOnFocus={true}
            placeholder="(Type to update description)"
            width="100%"
            customHeight="20px"
            value={item.description}
            maxResizeHeight={70}
            disableAutoResize={false}
            onBlur={handleUpdateDescription}
            getValue={(description) => {
              setDescription(description);
            }}
          />
          <div
            className="ellipsis"
            onClick={(e) => {
              e.stopPropagation();
              setIsActionClicked(true);
            }}
          >
            <EllipsisIcon width="12" fillColor="#484848" />
          </div>
        </TextDescription>
      </Text>
      <RemoveButton
        isVisible={isActionClicked}
        ref={buttonRef}
        onClick={async (e) => {
          e.stopPropagation();
          props.handleRemove();
          setIsActionClicked(false);
        }}
      >
        Remove <DeleteIcon />
      </RemoveButton>
    </Entry>
  );
});

export default FileListItem;

const Entry = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  height: 59px;
  position: relative;
  // .target:hover + .enlarged {
  //   display: block;
  // }

  ${(props) =>
    props.isSelected &&
    css`
      // background-color: #4f4f4f;
      border-radius: 8px;
    `}
`;
const Text = styled.div`
  width: 65%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
  height: 59px;

  .quote {
    width: 100%;
    white-space: nowrap;
    color: #45d483;
    font-weight: 500;
    line-height: 19.2px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
`;

const TextDescription = styled.div`
  display: flex;
  gap: 3px;
  .text {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 38px;
    overflow-y: hidden;
  }
  .ellipsis {
    width: 10%;
    margin-left: auto;
    text-align: right;
  }
`;
const Wrapper = styled.div<{ hasImage: boolean }>`
  border: 1px solid #03041926;
  color: #4f4f4f;
  width: 100px;
  height: ${SMALL_PHOTO_HEIGHT}px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: top;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  // object-position: top;
`;

const RemoveButton = styled.button<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  position: absolute;
  right: 0;
  bottom: -28px;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 15px 12px;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 400;
  gap: 25px;
  z-index: 1;
  cursor: pointer;
`;

const Description = styled.textarea`
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  display: flex;
  gap: 16px;
  border: none;
  text-align: left;
  font-size: 12px;
  background-color: transparent;
  color: #ddd;
  width: 100%;
  resize: none;
  height: 30px;
  min-height: 30px;
  transition: height 0.5s ease;
  overflow: auto;
  overflow-y: hidden;
  outline: 0;
`;

const EnlargedImageContainer = styled.div<{
  top: number;
  left: number;
  arrowPos: number;
  isVisible: boolean;
  actualWidth: number;
}>`
  position: fixed;
  left: ${(props) => props.left}px;
  top: ${(props) =>
    Math.min(props.top, getDocumentHeight() - ENLARGED_PHOTO_HEIGHT)}px;
  width: ${(props) => props.actualWidth}px;
  height: ${ENLARGED_PHOTO_HEIGHT}px;
  border-radius: 12px;
  background-color: #f3e9d7;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  &::after {
    content: '';
    position: absolute;
    top: ${(props) => props.arrowPos}%;
    left: 100%;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 12px 0 12px 8px;
    border-color: transparent transparent transparent #f3e9d7;
  }
`;

const EnlargedImage = styled.img<{ actualWidth: number | null }>`
  width: ${(props) => props.actualWidth || 'auto'};
  border-radius: inherit;
  height: 337px;
`;
