import { Client, SimpleSchemaTypes } from '@datocms/cma-client-browser';
import {
  ALBUM_QUERY,
  STORY_QUERY,
  ALL_VIDEO_SOURCES_IN_STORY_QUERY,
  ALBUM_STORY_IDS_QUERY,
} from '../utility/gql';
import { GraphQLClient } from 'graphql-request';
import {
  AiGeneratedContent,
  AlbumQueryResult,
  AlbumStory,
  Artifact,
  PunchListItem,
  Story,
  StoryDTO,
  StoryQueryResult,
} from '../types.ts/story';
import TurndownService from 'turndown';
import { TalkingPointContent } from '../types.ts/general';
import ApiClient from '../apiClient/ApiClient';
import { v4 as uuid } from 'uuid';
import transformKeysToCamelCase from '../utility/transformKeysToCamelCase';
import { request } from '../utility/dato';
const turndownService = new TurndownService();

export class StoryRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async findMany(
    albumId: string,
    clientOptions: {
      includeDrafts: boolean;
      excludeInvalid: boolean;
      environment: string;
    },
  ): Promise<AlbumStory[]> {
    const response = (await request({
      query: ALBUM_QUERY,
      variables: {
        id: albumId,
      },
      ...clientOptions,
    })) as Record<'showcase', AlbumQueryResult>;
    if (!response?.showcase?.stories) return [];
    return response.showcase.stories;
  }

  async findOne(id: string): Promise<Story | null> {
    const response = (await this.gqlClient.request(STORY_QUERY, {
      id,
    })) as StoryQueryResult;
    if (!response.story) return null;

    // separate punchlist out of other ai generated content to work with it easier
    const punchList = response.story.aiResponse?.responses?.find(
      (item) => item.title === 'Photo Punchlist',
    ) as AiGeneratedContent<'Photo Punchlist'> | undefined;

    const followUpPunchList =
      response.story.aiResponse?.followUpResponses?.find(
        (item) => item.title === 'Photo Punchlist',
      ) as AiGeneratedContent<'Photo Punchlist'> | undefined;

    const storyClips = response.story.otherVideos?.map((clip) => ({
      ...clip,
    }));

    const story: Story = {
      ...response.story,
      otherVideos: storyClips || [],
      allVideos: storyClips || [],
      punchList: punchList
        ? (punchList.response as unknown as PunchListItem[])
        : [],
      followUpPunchList: followUpPunchList
        ? (followUpPunchList.response as unknown as PunchListItem[])
        : [],
      // sharableImages: sharableImages || [],
    };
    return story;
  }

  async create(
    story: Partial<StoryDTO>,
    originalVideoUploadId?: string,
  ): Promise<Story> {
    if (!story.title) {
      throw new Error('Story title is required');
    }
    if (!story.storyTeller?.id) {
      throw new Error('Storyteller id is required');
    }

    const itemType = await this.dClient.itemTypes.find('story');
    const createdItem = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      hash: uuid(),
      slug: uuid(),
      ...(originalVideoUploadId && {
        original_video: {
          upload_id: originalVideoUploadId,
        },
      }),
      ...(story.title && {
        title: { en: story.title },
      }),
      ...(story.storyType && {
        story_type: story.storyType,
      }),
      ...(story.byExternalUser != null && {
        by_external_user: story.byExternalUser,
      }),
      ...(story.storyTeller?.id && {
        story_teller: story.storyTeller.id,
      }),
    });

    if (story.primaryShowcase?.id) {
      await this.addAsReferenceToShowcase(
        createdItem.id,
        story.primaryShowcase.id,
      );
    }

    return this.itemToStory({ ...createdItem, ...story });
  }

  private async fetchShowcaseWithStoryIds(
    showcaseId: string,
  ): Promise<{ showcase?: { stories: { id: string }[] } }> {
    return this.gqlClient.request(ALBUM_STORY_IDS_QUERY, {
      id: showcaseId,
    });
  }

  private async addAsReferenceToShowcase(storyId: string, showcaseId: string) {
    const showcaseRecord = await this.fetchShowcaseWithStoryIds(showcaseId);
    if (showcaseRecord?.showcase) {
      await this.dClient.items.update(showcaseId, {
        stories: showcaseRecord.showcase.stories
          .map((s) => s.id)
          .concat(storyId),
      });
    }
  }

  async removeReferenceFromShowcase(storyId: string, showcaseId: string) {
    const showcaseRecord = await this.fetchShowcaseWithStoryIds(showcaseId);
    if (showcaseRecord?.showcase) {
      await this.dClient.items.update(showcaseId, {
        stories: showcaseRecord.showcase.stories
          .map((s) => s.id)
          .filter((id) => id !== storyId),
      });
    }
  }

  async update(
    story: Partial<StoryDTO> & { id: string },
    originalVideoUploadId?: string,
  ): Promise<Story> {
    if (!story.id) {
      throw new Error('Story id is required');
    }

    const updatedItem = await this.dClient.items.update(story.id, {
      ...(story.title && {
        title: { en: story.title },
      }),

      // DO NOT OVERWRITE VIDEOS TO AVOID DELETING THEM
      // Instead, videos are added and removed directly with addVideoToStory and removeVideoFromStory
      // ...(story.otherVideos && {
      //   other_videos: story.otherVideos.map((video) => video.id),
      // }),

      ...(story.finalVideo && {
        final_video: story.finalVideo.id,
      }),

      ...(story.shareableImages && {
        shareable_images: story.shareableImages.map((s) => s.id),
      }),

      ...(story.aiPhotos && {
        ai_photos: await Promise.all(
          story.aiPhotos.map((photo) => ({ upload_id: photo.id })),
        ),
      }),

      ...(story.storyAssets && {
        story_assets: await Promise.all(
          story.storyAssets.map((photo) => ({
            upload_id: photo.id,
            ...((photo as Artifact).title && {
              title: (photo as Artifact).title,
            }),
          })),
        ),
      }),

      ...(story.storyArtifacts && {
        story_artifacts: await Promise.all(
          story.storyArtifacts.map((photo) => ({
            upload_id: photo.id,
            ...((photo as Artifact).title && {
              title: (photo as Artifact).title,
            }),
          })),
        ),
      }),

      ...(story.storyArtifactsVideo && {
        story_artifacts_video: await Promise.all(
          story.storyArtifactsVideo.map((video) => ({
            upload_id: video.id,
            ...((video as Artifact).title && {
              title: (video as Artifact).title,
            }),
          })),
        ),
      }),

      ...(story.contributors && {
        contributors: story.contributors.map((c) => c.id),
      }),

      ...(story.myAudios && {
        my_audios: story.myAudios.map((audio) => audio.id),
      }),

      ...(originalVideoUploadId && {
        original_video: {
          upload_id: originalVideoUploadId,
        },
      }),
    });
    await this.dClient.items.publish(story.id);

    return this.itemToStory(updatedItem);
  }

  async addVideoToStory(storyId: string, videoId: string) {
    const story = await this.dClient.items.find(storyId);
    const updatedItem = await this.dClient.items.update(storyId, {
      other_videos: [videoId].concat((story.other_videos as string[]) || []),
    });
    await this.dClient.items.publish(storyId);
  }

  async removeVideoFromStory(storyId: string, videoId: string) {
    const story = await this.dClient.items.find(storyId);
    const updatedItem = await this.dClient.items.update(storyId, {
      other_videos: (story.other_videos as string[]).filter(
        (id) => id !== videoId,
      ),
      final_video: story.final_video === videoId ? null : story.final_video,
    });
    await this.dClient.items.publish(storyId);
  }

  private itemToStory(item: SimpleSchemaTypes.Item): Story {
    return {
      ...transformKeysToCamelCase(item),
      title:
        typeof item.title === 'string'
          ? item.title
          : (item.title as { en?: string })?.en,
      _publishedAt: item.meta?.published_at,
    };
  }

  async handleSaveAiGeneratedContent(
    storyId: string,
    data: any,
    type: 'saved_blog' | 'saved_email' | 'saved_talking_point_content',
  ) {
    await this.dClient.items.update(storyId, {
      [`${type}`]: JSON.stringify(data, null, 2),
    });
    await this.dClient.items.publish(storyId);
  }

  async saveTalkingPointContent(
    story: Pick<Story, 'id' | 'savedTalkingPointContent'>,
    data: Record<
      keyof TalkingPointContent,
      Record<'title' | 'content' | 'prompt', string>
    >,
    title: string,
  ) {
    const savedData = story?.savedTalkingPointContent || {};
    let existingKey: number | undefined = undefined;

    for (let [key, content_data] of Object.entries(savedData)) {
      const hasMatch = Object.entries(content_data.content).every(
        ([key, value]) => {
          const k = key as keyof TalkingPointContent;
          const enterContentMk = turndownService.turndown(data[k].content);
          const existingMk = turndownService.turndown(value.content);

          return existingMk === enterContentMk && value.title === data[k].title;
        },
      );
      if (hasMatch) {
        existingKey = Number(key);
        break;
      }
    }

    if (existingKey) {
      delete savedData[existingKey];
    }

    savedData[new Date().getTime()] = { title, content: data };
    await this.handleSaveAiGeneratedContent(
      story.id,
      savedData,
      'saved_talking_point_content',
    );
    return savedData;
  }

  async saveBlogOrEmail(
    story: Pick<Story, 'id' | 'savedBlog' | 'savedEmail'>,
    data: string,
    title: string,
    userName: string | null | undefined,
    type: 'saved_blog' | 'saved_email',
  ) {
    let savedData = type === 'saved_email' ? story.savedEmail : story.savedBlog;

    savedData = savedData || {};
    const currDataMarkdown = turndownService.turndown(data);

    let existingKey: number | undefined = undefined;

    for (let [key, data] of Object.entries(savedData)) {
      const dataMarkdown = turndownService.turndown(data.content);
      if (currDataMarkdown === dataMarkdown) {
        existingKey = Number(key);
        break;
      }
    }

    if (existingKey) {
      delete savedData[existingKey];
    }

    const username = userName ?? 'Arbor Admin';
    savedData[new Date().getTime()] = { title, content: data, username };
    await this.handleSaveAiGeneratedContent(story.id, savedData, type);
    return savedData;
  }

  async getAllVideoSourcesInStoryId(id: string) {
    const response = (await this.gqlClient.request(
      ALL_VIDEO_SOURCES_IN_STORY_QUERY,
      {
        id,
      },
    )) as {
      story: Pick<Story, 'originalVideo' | 'previousOriginalVideo'> & {
        otherVideos: {
          id: string;
          videoJson: Record<string, any>;
          associatedVideos: {
            id: string;
            videoJson: Record<string, any>;
          }[];
        }[];
      };
    };
    if (!response.story) return null;
    return response.story;
  }

  public async createShareableImage(
    story: Pick<Story, 'id' | 'title' | 'storyTeller'>,
    quote: string,
    imagefile?: Artifact,
    storytellerName?: string,
  ) {
    const itemType = await this.dClient.itemTypes.find('shareable_image');
    const savedShareableImage = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType!.id },
      story_id: story.id,
      title: `${story.title} - ${story.id}`,
      quote,
      storyteller_name: storytellerName || story.storyTeller?.name,
      ...(imagefile && {
        imagefile: {
          upload_id: imagefile.id,
        },
      }),
    });
    return savedShareableImage.id;
  }

  public async updateShareableImage(
    id: string,
    quote: string,
    imagefile?: Artifact,
    storytellerName?: string,
  ) {
    await this.dClient.items.update(id, {
      quote,
      storyteller_name: storytellerName,
      ...(imagefile && {
        imagefile: {
          upload_id: imagefile.id,
          alt: imagefile.responsiveImage?.alt,
        },
      }),
    });
    return id;
  }

  async deleteTranscription(storyId: string) {
    await this.dClient.items.update(storyId, {
      locked: false,
      transcription: null,
    });
    await this.dClient.items.publish(storyId);
  }
}
