import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Artifact, FileData, Story } from '../../types.ts/story';
import { videoCreator } from '../../stores/VideoCreatorStore';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import { ActionButton, ActionsWrapper } from '../../styles/mainStyle';
import { formatUploadToArtifact } from '../../utility/general';
import { observer } from 'mobx-react-lite';

type Props = {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  Button?: React.ReactNode;
  showToggle?: boolean;
  width?: string;
  onClick: () => void;
  type?:
    | 'storyArtifacts'
    | 'storyArtifactsVideo'
    | 'organizationArtifacts'
    | 'organizationArtifactsVideo'
    | 'organizationLogos';
  callback?: (story: Story) => void;
};

const FileUpload = observer((props: Props) => {
  const { setIsLoading, showToggle = true, type } = props;
  const [showDropdown, toggleDropdown] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useOutsideAlerter(buttonRef, () => {
    toggleDropdown(false);
  });

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = [...(e.target.files || [])];

    if (!files.length) return;
    try {
      setIsLoading(true);
      const uploadResults = await Promise.all(
        files.map(async (file) => {
          const fileName = file.name.split('.')[0];
          const newFileData: FileData & { fileName: string } = {
            type: 'artifact',
            file,
            fileName,
            alt: '',
            title: '',
          };

          const newUpload =
            await videoCreator.assetRepository?.uploadFile(newFileData);
          return formatUploadToArtifact(newUpload!, {});
        }),
      );

      const photoUploads =
        uploadResults.filter((u) => u?.responsiveImage).filter(Boolean) || [];

      const videoUploads =
        uploadResults.filter((u) => !u.responsiveImage).filter(Boolean) || [];

      const storyData = videoCreator.story;

      if (
        (photoUploads?.length || videoUploads?.length) &&
        (!type || type === 'storyArtifactsVideo' || type === 'storyArtifacts')
      ) {
        const existingPhotos = storyData?.storyArtifacts || [];
        const existingVids = storyData?.storyArtifactsVideo || [];

        storyData!.storyArtifactsVideo! = [
          ...existingVids,
          ...videoUploads,
        ] as Artifact[];

        storyData!.storyArtifacts! = [
          ...existingPhotos,
          ...photoUploads,
        ] as Artifact[];

        await videoCreator.updateStory(storyData!);
      }

      if (
        (photoUploads?.length || videoUploads?.length) &&
        (type === 'organizationArtifactsVideo' ||
          type === 'organizationArtifacts' ||
          type === 'organizationLogos') &&
        storyData?.primaryShowcase
      ) {
        const albumId = storyData.primaryShowcase.id;
        const albumIdx = storyData._allReferencingShowcases.findIndex(
          (s) => s.id === albumId,
        );
        const album =
          albumIdx > -1
            ? storyData._allReferencingShowcases[albumIdx]
            : storyData.primaryShowcase;

        const existingPhotos = album?.organizationArtifacts || [];
        const existingVids = album?.organizationArtifactsVideo || [];
        const existingLogos = album?.organizationLogos || [];

        album!.organizationArtifactsVideo = [
          ...existingVids,
          ...videoUploads,
        ] as Artifact[];

        if (type === 'organizationLogos') {
          album!.organizationLogos = [
            ...existingLogos,
            ...photoUploads,
          ] as Artifact[];
        } else {
          album!.organizationArtifacts = [
            ...existingPhotos,
            ...photoUploads,
          ] as Artifact[];
        }

        await videoCreator.albumRepository?.update(album);
        storyData.primaryShowcase = album;
        const showcaseIdx = storyData._allReferencingShowcases?.findIndex(
          (s) => s.id === album.id,
        );
        if (showcaseIdx > -1) {
          storyData._allReferencingShowcases[showcaseIdx] = album;
        } else {
          storyData._allReferencingShowcases.push(album);
        }
      }

      // await videoCreator.updateStory(storyData!);
      // const story = await videoCreator.storyRepository!.findOne(storyData!.id);
      // if (story) videoCreator.story = story;
      videoCreator.story = storyData;
      if (props.callback) {
        props.callback(videoCreator.story!);
      }
    } catch (error) {
      console.log('Error: ', error);
    } finally {
      setIsLoading(false);
    }
  };

  const picture_formats = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
  const video_formats = ['video/mp4'];
  function getFormats() {
    switch (type) {
      case 'storyArtifacts':
      case 'organizationArtifacts':
      case 'organizationLogos':
        return picture_formats;
      case 'storyArtifactsVideo':
      case 'organizationArtifactsVideo':
        return video_formats;
      default:
        return picture_formats.concat(video_formats);
    }
  }
  const formats = getFormats();
  return (
    <Main width={props.width}>
      <Kebab
        ref={buttonRef}
        onClick={() => {
          toggleDropdown(!showDropdown);
          props.onClick();
        }}
      >
        {props.Button}

        {showToggle ? (
          <Actions isVisible={showDropdown}>
            <AddMedia>
              <span>Add Artifact</span>
              <File
                type="file"
                title=""
                multiple
                accept={formats.join(',')}
                onChange={handleFileChange}
              />
            </AddMedia>
          </Actions>
        ) : (
          <File
            type="file"
            title=""
            multiple
            accept={formats.join(',')}
            onChange={handleFileChange}
          />
        )}
      </Kebab>
    </Main>
  );
});

export default FileUpload;

const Main = styled.div<{ width?: string }>`
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

const Actions = styled(ActionsWrapper)`
  // right: unset;
`;

const AddMedia = styled(ActionButton)<{ showToggle?: boolean }>`
  border-radius: 8px !important;
`;

const File = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const Kebab = styled.button`
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 0;
`;
